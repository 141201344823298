@import './root';
@import './mixins';

.custom-toggle-switch {
    position: relative;
    display: inline-block;
    width: 125px;
    height: 34px;
}

.custom-toggle-input {
    opacity: 0;
    width: 0;
    height: 0;
}

.custom-toggle-label {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    cursor: pointer;
    line-height: 34px;
    font-weight: 500;
    font-size: 14px;
    color: var(--sidenavbar-text-color);
    transition: background-color 0.4s, text-align 0.4s;
    border-radius: 5px;
}

.custom-toggle-input:checked+.custom-toggle-label {
    background-color: var(--tik-mark-color);
    /* Green for enabled state */
    text-align: left;
    padding-left: 0.5rem;
}

.custom-toggle-label.disabled {
    background-color: var(--cancel-btn-text-color);
    /* Red for disabled state */
    text-align: right;
    padding-right: 0.5rem;
}

.custom-toggle-label:before {
    content: "";
    position: absolute;
    width: 60px;
    height: 26px;
    background-color: white;
    top: 4px;
    left: 4px;
    transition: transform 0.4s;
    border-radius: 5px;
}

.custom-toggle-input:checked+.custom-toggle-label:before {
    transform: translateX(57px);
    /* Moves the switch to the right end */
}

.custom-toggle-label.disabled:before {
    transform: translateX(0px);
    /* Keeps the switch at the left end when disabled */
}

.customer-management-container {
    .customermanagement-content {
        background-color: var(--sidenavbar-color);

        .module-management-container {
            // padding: 0;
            padding: 1rem 2rem;
            background-color: var(--user-profile-bg-color);

            h6 {
                // padding: 1rem 2rem 0.5rem;
                padding: 0;
                font-weight: 700;
                margin: 0;
            }

            .modules-grid1 {
                border-right: 2px solid var(--modulename-border-color);
                padding-left: 0;
            }

            .modules-grid2 {
                padding-right: 0;
            }

            .module-container {

                .modulename-header-div {
                    @include displayFlexBetween;
                    border: 1px solid var(--modulename-border-color);
                    padding: 0.5rem;
                    border-radius: 5px;

                    p {
                        color: var(--next-btn-bg-color);
                        font-weight: 700;
                        font-size: 0.9rem !important;
                        margin: 0 !important;
                    }
                }

                .module-names-container {
                    // @include displayFlexCenterStart;
                    // gap: 3rem;
                    // padding-top: 0.7rem;
                    // flex-wrap: wrap;

                    display: grid;
                    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
                    /* Adjust minmax value as needed */
                    gap: 1rem;
                    padding-top: 0.7rem;

                    .form-check {
                        font-size: 0.9rem;
                        font-weight: 500;
                    }
                }
            }

            .module-management-select {
                // padding: 0.5rem 2rem 0.5rem;
                padding: 1rem 0;

                label {
                    padding: 0.5rem 0rem;
                    font-size: 0.85rem;
                    color: rgba(137, 137, 137, 1);
                }
            }

            .fields-container {
                padding: 1rem 1rem;
                border: 1px solid var(--modulename-border-color);
                border-radius: 0.375rem;

                label {
                    font-size: 0.85rem;
                }

                .custom-field-edit-icon {
                    filter: invert(51%) sepia(99%) saturate(436%) hue-rotate(144deg) brightness(103%) contrast(99%);
                    height: 12px;
                    width: 12px;
                }

                .menubar-fields-text-box-div {
                    position: relative;

                    .add-custom-icon {
                        position: absolute;
                        top: 0px;
                        right: 0px;
                        height: 35px;
                        width: 35px;
                        background-color: var(--buttons-bg-color);
                        padding: 12px;
                        border-radius: 0px 4px 4px 0px;
                    }
                }
            }

            .field-table-btn-container {
                @include displayFlex2End;
                gap: 1rem;
                padding: 0;
            }
        }
    }
}

@media (max-width:768px) {
    .modulename-header-div {
        flex-direction: column;
        align-items: flex-start !important;
    }
}