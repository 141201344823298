.add-workqueue-modal {
    .workqueue-list-items {
        .list-group-item {
            display: grid;
            grid-template-columns: 120px 8px minmax(0, 1fr);
            border: none;
            padding: 0;
            word-wrap: break-word;

            p {
                &:first-of-type {
                    color: var(--previous-btn-text-color);
                    font-weight: 400;
                }

                &:last-of-type {
                    color: var(--view-page-text-color);
                    font-weight: 400;
                    white-space: normal;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }
    }

    .no-of-license-footer {
        justify-content: space-between;

        p {
            color: var(--next-btn-bg-color);
            font-weight: 700;
        }

        .no-of-license-btns {
            display: flex;
            gap: 0.8rem;
        }
    }
}

.work-queue-tabs {
    .nav-tabs {
        padding: 1rem 1.5rem 0rem 0rem !important;
        border: none !important;
        background-color: var(--sidenavbar-color) !important;
        .nav-item{
            width: 225px;
            text-align: center;
        }
    }
}