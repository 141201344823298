@import './root';
@import './mixins';

.initechNode {
    // border: solid 3px red;
    border-radius: 3px;
    padding: 5px;
    // width: 150px;
    width: max-content;
    display: inline-block;
    position: relative;
}

#initechOrgChart .orgNodeChildGroup .nodeGroupLineVerticalMiddle {
    border-right: solid 3px #5842bc;
}

#initechOrgChart .nodeLineBorderTop {
    border-top: solid 3px #5842bc;
}

#initechOrgChart tbody tr {
    border-bottom: none !important;
}

#initechOrgChart tbody tr td {
    padding: 0px !important;
    border-bottom: none !important;
}

.organization-cahrt-container {
    background-color: var(--next-btn-text-color);
    padding: 1rem;

    span {
        color: var(--next-btn-bg-color);
        font-weight: 700;
    }
}

.hierarchy-image img {
    width: 50px;
    height: 50px;
    border: 2px solid #5842bc;
    border-radius: 50%;
    padding: 5px;
}

.hierarchy-positions {
    border-radius: 10px;
    margin: auto;
    padding: 10px;
    color: black;
    background-color: #EDFCF9;

    b {
        font-size: 0.75rem;
    }

    p {
        margin: 0 !important;
    }
}

.hierarchy-div {
    overflow: auto;
    padding: 20px;
}

/* Add this to your CSS */
.hierarchy-hover-div {
    display: none;
    /* Initially hide the div */
    position: absolute;
    // top: 100%;
    left: 130px;
    background-color: white;
    border: 1px solid #ccc;
    padding: 5px;
    z-index: 1;
    border-radius: 3px;
    // width: 300px;
    // height: 100%;
    padding: 20px;
}

.hierarchy-hover-div.visible {
    display: block;
    /* Show the div when visible class is applied */
}

.user-email {
    display: flex;
    justify-content: flex-start;
}

.user-email p {
    margin-right: 8px;
}