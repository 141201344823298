@import './root';
@import './mixins';

.dashboard-container {
    .dashboard-btns-container {
        // padding-bottom: 1%;
        // padding: 1%;

        .dashboard-backbtn-div {
            @include displayFlexCenterStart;

            img {
                padding: 1%;
                margin-left: 3%;
            }
        }

        .dashboard-header-btns {
            // margin-left: 15%;
            @include displayFlexCenterEnd;
            margin-right: 3%;

            .btn {
                background-color: var(--primary-color);
                border: none;
                border-radius: 0.3rem;
                margin-right: 1%;

                &:focus {
                    box-shadow: none;
                }
            }

            .download-btn {
                color: white;
            }

            .dropdown-toggle::after {
                display: none;
            }
        }
    }

    .dashboard-cards-container {
        @include displayFlexBetween;
        padding: 2% 2% 2% 0%;
        background-color: var(--sidenavbar-color);
        // gap: 1rem;
        margin: 0;

        .dashboard-cards {
            // width: 23%;
            height: 110px;
            border: 1px solid rgba(229, 229, 229, 1);
            @include displayFlexBetween;
            border-radius: 3%;
            background-color: var(--user-profile-bg-color);
            // flex: 1 1 19.5%;
            padding: 1rem;
            cursor: pointer;

            .dashboard-cards-counts {
                p {
                    margin: 0;
                }

                b {
                    font-size: 1.5rem;
                    font-weight: 500;
                }

                .dashboard-expiring-text {
                    color: rgba(143, 145, 143, 1);
                    padding: 2px 0px;
                    font-size: 12px !important;
                }

                .dashboard-progress {
                    height: 0.2rem;
                }
            }
        }

        .dashboard-cards-image {
            width: 60px;
            height: 60px;
            position: relative;
            background-color: var(--total-customer-img-bg-color);
            border-radius: 5%;
            display: flex;
            justify-content: center;
            align-items: center;

            img {
                // position: absolute;
                // top: 14%;
                // right: 15%;
                height: 30px;
            }
        }
    }

    .dashboard-charts {
        // padding: 0% 2%;
        background-color: var(--sidenavbar-color);
        // gap: 1rem;
        margin: 0;

        .dashboard-charts-div {

            // flex: 1 1 19.5%;
            .charts-container {
                padding-right: 1rem;
                height: 100%;
            }
        }
    }

    .barchart-container {
        border: 1px solid rgba(229, 229, 229, 1);
        border-radius: 1%;
        padding: 3%;
        background-color: rgba(255, 255, 255, 1);
        // height: 100%;
        height: 350px;

        h6 {
            color: var(--sidenavbar-text-color) !important;
            font-weight: 500;
        }

        .btn {
            background-color: var(--dashboard-dropdown-btn-bg-color);
            border: none;
            color: var(--primary-text-color);

            &:focus {
                background-color: var(--dashboard-dropdown-btn-bg-color);
                outline: none;
                color: var(--primary-text-color);
                box-shadow: none;
            }
        }

        .dropdown-menu {
            min-width: 80px !important;
            max-width: 250px !important;
            height: 225px !important;
            overflow: auto;

            .dropdown-item {
                background-color: var(--user-profile-bg-color); // Default background color
                color: var(--primary-text-color) !important;

                &:focus,
                &:hover,
                &:active {
                    background-color: var(--dashboard-dropdown-btn-bg-color); // Background color on focus and hover
                    color: var(--primary-text-color) !important; // Optional: Adjust text color
                }
            }

        }

        .custom-dropdown-menu {
            min-width: 6rem !important;
            transform: translate3d(-25px, 35.3333px, 0px) !important;
        }

        .attendance-status-container {
            @include displayFlexBetween;
            padding-bottom: 1rem;
        }

        .barchart-canvas-container {
            width: 100% !important;
            height: 90% !important;

            canvas {
                width: 100% !important;
                height: 90% !important;
            }
        }

    }

    .attendence-leaves-container {
        padding-right: 2rem;
    }

    .holiday-schedule-card {
        .holiday-schedule-card-body {
            .holiday-schedule-card-header {
                @include displayFlexBetween;
            }
        }

        .holiday-date {
            background-color: rgb(214, 216, 217) !important;
            text-align: center;
            padding: 0.5rem 1rem;
            border-radius: 5px;

            h6 {
                color: var(--primary-text-color);
                font-weight: 600;
            }

            small {
                font-size: 0.65rem !important;
            }
        }

        .holiday-names {
            small {
                font-size: 0.65rem !important;
                color: var(--previous-btn-text-color);
            }
        }
    }
}

.relation-type-chart-container {
    .relation-type-chart-btns {
        @include displayFlexCenterEnd;
        gap: 0.4rem;
    }

    .assigned-module-div {
        // @include displayFlexCenter;
        // justify-content: space-between;
        // flex-wrap: wrap;
        padding-top: 1rem;
    }

    .assigned-module-names {
        display: flex;
        align-items: center;
        // width: calc(50% - 10px);
        // padding-bottom: 1rem;
        padding-bottom: 0.5rem;

        span {
            font-size: 0.8rem !important;
        }
    }

    .customer-status-chart {
        @include displayFlexBetween;
        padding-top: 1rem;

        .relationship-indicators-container {
            .relationship-indicators-names {
                margin-left: 1rem;
                font-size: 0.875rem;
            }
        }
    }
}

.funnel-chart-header-div {
    @include displayFlexCenter;
    justify-content: space-between;

    .funnel-chart-btns {
        @include displayFlexCenterEnd;
        gap: 0.4rem;
    }
}

.funnel-chart {
    // height: 425px;
    height: 90%;
    padding: 1rem;
}

// Custom View Form
.save-custom-view-modal {
    .modal-dialog {
        .modal-content {
            .modal-title {
                font-size: 1rem;
                font-weight: 700;
            }
        }
    }

    .customer-view-form-fields {
        p {
            margin: 0;
            padding: 0.5rem 0rem;
        }

        label {
            font-size: 0.75rem;
        }

        .custom-view-form-checkbox-container {
            padding: 1rem 0rem;
        }
    }
}

// Custom View Form

@media (min-width: 1024px) and (max-width: 1439px) {
    .barchart-container {
        height: 365px !important;

        h6 {
            font-size: 0.8rem;
        }

        .attendance-status-container {
            @include displayColumnStartCenter;
            justify-content: flex-start !important;
            align-items: flex-start !important;
        }

        .dropdown {
            .btn {
                background-color: var(--dashboard-dropdown-btn-bg-color) !important;
                font-size: 0.75rem !important;

                &:focus {
                    box-shadow: none !important;
                }
            }

            .dropdown-menu {
                .dropdown-item {
                    font-size: 0.75rem;
                }
            }
        }

        .holiday-schedule-card {
            h6 {
                font-size: 0.8rem;
            }
        }

        .customer-status-chart {
            padding-top: 0.5rem;

            .relationship-indicators-container {
                width: 100% !important;
                display: flex;
                flex-wrap: wrap;
                // font-size: 0.8rem !important;
                gap: 0.5rem;
                align-items: center;
                margin-top: 10px;

                .relationship-indicators-names {
                    align-items: baseline;
                    display: flex;
                    gap: 0.5rem;
                    padding: 0.5rem;
                    font-size: 0.875rem !important;
                    // margin-left: 2rem;
                }
            }

            .customer-status-canvas-div {
                height: 180px !important;
                width: 100%;

                .customer-status-indicator-names {
                    padding: 0.5rem;
                }
            }
        }

        .funnel-chart-header-div {
            flex-direction: column;
            align-items: flex-start;
            row-gap: 0.5rem;
            padding: 0.3rem 0rem;

            // .funnel-chart {
            //     height: 280px;
            //     padding: 0.5rem 0rem;
            // }
        }

        .funnel-chart {
            // height: 260px;
            height: 80%;
            padding: 0.5rem 0rem;
        }
    }

    .relation-type-chart-container {
        .relation-type-chart-btns {
            justify-content: flex-start;
            flex-wrap: wrap;
        }

        .customer-status-chart {
            display: flex;
            flex-direction: column;
            // height: 200px;

            .highchart-container {
                width: 90% !important;
                height: 100%;
            }
        }
    }

    .holiday-schedule-card {
        .holiday-schedule-card-body {
            .holiday-schedule-card-header {
                h6 {
                    font-size: 0.8rem;
                    margin: 0;
                }
            }

            .holiday-date {
                background-color: rgb(214, 216, 217) !important;
                text-align: center;
                padding: 0.3rem 0.6rem;
                border-radius: 5px;

                small {
                    font-size: 0.65rem !important;
                    color: var(--previous-btn-text-color);
                }
            }

            .holiday-schedule-chevron-month-div {
                .holiday-schedule-chevron-img {
                    width: 30px !important;
                    height: 30px !important;
                }
            }

            hr {
                margin-top: 1px !important;
            }

            .holiday-list {
                max-height: 300px !important;
            }
        }
    }

    .calendar-date {
        height: 40px !important;
        font-size: 0.70rem !important;
    }

    .assigned-module-div {
        height: 250px;
        overflow: auto;
        // @include customScrollbar;
    }

    ::-webkit-scrollbar {
        width: 4px;
    }

    ::-webkit-scrollbar-track {
        background-color: var(--table-header-color);
    }

    ::-webkit-scrollbar-thumb {
        background-color: var(--scroller-bg-color);
        border-radius: 4px;
    }

    .dashboard-cards-counts {
        p {
            font-size: 0.8rem !important;
        }

        b {
            font-size: 1rem !important;
        }
    }

    .dashboard-cards-image {
        width: 50px !important;
        height: 50px !important;

        img {
            height: 30px !important;
        }
    }
}

/* Default styles (for desktops) */
.carousel-container {
    display: none;
    /* Hide carousel by default */
}

.relationship-indicators-names {
    display: block;
    font-size: 0.875rem;
}

@media (min-width: 1024px) and (max-width:1440px) {
    .carousel-container {
        display: block;
        font-size: 0.8rem !important;
        padding: 0rem 1rem;
    }

    .carousel-none-div {
        display: none;
    }
}

@media (min-width:1440px) and (max-width: 1919px) {
    .barchart-container {
        .funnel-chart {
            // height: 325px !important;
            height: 85% !important;
        }

        .relationship-indicators-names {
            font-size: 0.875rem !important;
        }
    }

    .dashboard-cards-counts {
        p {
            font-size: 0.8rem !important;
        }

        b {
            font-size: 1rem !important;
        }
    }

    .dashboard-cards-image {
        width: 50px !important;
        height: 50px !important;

        img {
            height: 30px !important;
        }
    }
}