@import './root';
@import './mixins';

.user-profile-form-container {
    gap: 2rem;
    margin: 0;
    padding: 3rem 10rem 5rem;

    .user-profile-form-first-col {
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: var(--user-profile-bg-color);

        img {
            width: 100px;
            height: 100px;
            margin: 0.8rem;
        }

        .user-profile-list-items {
            .list-group-item {
                text-align: center;
                border: none !important;
                padding: 0;
                word-break: break-all;

                p {
                    &:first-child {
                        color: var(--list-group-text1-color);
                        margin-bottom: 0;
                        font-weight: 400;
                    }

                    &:last-child {
                        color: var(--list-group-text2-color);
                        margin-top: 0;
                        font-weight: 600;
                    }
                }
            }
        }
    }

    .user-profile-form-second-col {
        background-color: var(--user-profile-bg-color);

        h6 {
            color: var(--user-profile-text-color);
            font-weight: 700;
            padding: 1rem;
            margin: 0;
        }

        hr {
            margin: 0.5rem 0rem;
            color: 1px solid var(--modulename-border-color);
        }

        .user-profile-form-row {
            padding: 0rem 1rem;
        }

        label {
            padding-bottom: 5%;
            padding-top: 5%;
            font-size: 0.8rem;
            color: var(--modal-label-color);
            font-weight: 400;
            margin: 0;
        }

        .user-profile-contact-div {
            display: flex;
            align-items: center;
            width: 100%;
            gap: 0.5rem;

            input {
                width: 70%;
            }

            .react-select-container {
                width: 30%;
            }
        }
    }

    .user-profile-submit-btn {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: 1rem 0rem;
    }
}

.profile-password-container {
    position: relative;

    .pass-hide-show {
        img {
            position: absolute;
            top: 40px;
            right: 8px;
        }
    }
}

.set-password-container {
    padding: 3rem !important;

    .profile-otp-form-text {
        color: rgba(137, 137, 137, 1);
        font-size: 0.7rem !important;
        text-align: center;
    }

    .profile-otp-form {
        .otp-input-box {
            display: flex;
            gap: 20px;
            justify-content: space-between;

            input {
                width: 50px;
                height: 46px;
            }
        }
    }
}

@media (min-width: 1024px) and (max-width: 1440px) {
    .user-profile-form-container {
        padding: 1rem 1rem;
    }

    .user-profile-contact-div {
        width: 100%;

        input {
            width: 58% !important;
        }

        .react-select-container {
            width: 42% !important;
        }
    }
}

@media (min-width:1440px) and (max-width: 1919px) {
    .user-profile-form-container {
        padding: 2rem 3rem 5rem !important;
    }
}