@import './root';
@import './mixins';

.settings-card-container {
    display: flex;
    align-items: center;
    // justify-content: center;
    height: 100px;
    padding: 10px;
    cursor: pointer;

    .settings-card-image {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 50px;
        width: 50px;
        background-color: var(--settings-img-bg-color);
        border-radius: 5%;
        padding: 8px;
    }

    .settings-text-container {
        display: flex;
        flex-direction: column;
        overflow-wrap: anywhere;
        padding: 10px 0px 10px 10px !important;

        h6 {
            color: var(--next-btn-bg-color);
            font-size: 0.88rem !important;
            font-weight: 500 !important;
        }

        p {
            color: var(--previous-btn-text-color);
            font-size: 0.7rem !important;
            font-weight: 400 !important;
        }
    }
}