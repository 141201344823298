@import './root';
@import './mixins';

// .add-user-modal {
//     position: relative;
// }

.add-user-submodal {
    label {
        padding-bottom: 4%;
        padding-top: 4%;
        font-size: 0.8rem;
        color: var(--modal-label-color);
        font-weight: 400;
    }

    .modal-dialog {
        position: absolute;
        right: 14.5%;
        width: 15%;

        .modal-content {
            // height: 72vh !important;
            height: 68vh !important;
            overflow: auto !important;

            .modal-title {
                font-size: 1rem;
                font-weight: 700;
            }
        }
    }
}

.drag-and-drop-area {
    .drag-drop-container {
        border: 2px dashed rgba(229, 229, 229, 1);
        padding: 5rem;

        p {
            margin: 0;
            color: rgba(229, 229, 229, 1);
        }

        .drag-drop-div {
            @include displayFlexCenter;

            .xls-Img {
                width: 50px;
            }
        }
    }

    .browse-btn-div {
        @include displayFlexCenter;
        padding: 0.8rem;
    }
}

.uploaded-files {
    .file-upload-progress {
        .file-info {
            @include displayFlexCenterStart;
            padding: 0.8rem 0;

            span {
                font-size: 1.2rem;
            }

            p {
                margin: 0;
            }

            .bulk-upload-delete-img {
                // height: 20px;
                // width: 20px;
                // border: 1px solid;
                // border-radius: 50%;
                // padding: 1px;
                padding: 5px;
            }
        }
    }
}