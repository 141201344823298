@import './root';
@import './mixins';

.customer-management-container {
    position: relative;

    .customermanagement-content-container {
        padding: 1rem 1.5rem 1.5rem 0rem;
        background-color: var(--sidenavbar-color);

        .customermanagement-content {
            // border: 1px solid var(--filter-table-section-border-color);
            // padding: 2rem
        }

        .customermanagement-download-container {
            @include displayFlexCenterEnd;

            .customermanagement-download-btns {
                .btn {
                    background-color: var(--primary-color);
                    border: none;
                    border-radius: 0.3rem;
                    margin-right: 1%;
                }
            }
        }

        .customermanagement-btns-container {
            padding-bottom: 1%;
            padding-top: 1%;
            background-color: var(--filter-section-bg-color);
            margin-right: 0;
            margin-left: 0;
            // border-bottom: 1px solid var(--filter-table-section-border-color);
            border: 1px solid var(--filter-table-section-border-color);
            border-bottom: none;

            .customermanagement-backbtn-div {
                @include displayFlexCenterStart;

                img {
                    padding: 1%;
                    margin-left: 3%;
                }

                span {
                    color: var(--next-btn-bg-color);
                    font-weight: 600;
                }
            }

            .customermanagement-search-filter {
                @include displayFlexCenterEnd;
                flex-wrap: wrap;
                gap: 0.5rem;

                .customermanagement-header-btns {
                    // margin-left: 15%;
                    @include displayFlexCenterEnd;
                    // margin-right: 3%;

                    .btn {
                        background-color: var(--primary-color);
                        border: none;
                        border-radius: 0.3rem;
                        margin-right: 1%;

                        .filter-img {
                            margin-right: 6%;
                            width: 14px;
                        }
                    }
                }

                .add-btn {
                    .btn {
                        height: 30px;
                        width: 140px;
                    }
                }

                .filter-btn {
                    position: relative;

                    .btn {
                        height: 30px;
                        width: 95px;
                    }

                    .filter-dropdown-options {
                        position: absolute;
                        top: 35px;
                        right: -2px;
                    }
                }

                .calendar-container {
                    position: relative;

                    img {
                        position: absolute;
                        right: 4%;
                        top: 14%;
                    }
                }

                .customermanagement-search {
                    position: relative;

                    img {
                        width: 30px;
                        height: 34px;
                        background-color: var(--buttons-bg-color);
                        padding: 1.5%;
                        position: absolute;
                        top: 1%;
                        right: 0%;
                        border-radius: 20%;
                    }


                    @media (min-width: 1024px) and (max-width: 1440px) {
                        img {
                            // padding: 2%;
                        }
                    }
                }
            }

        }

        .table-container {
            border: 1px solid var(--filter-table-section-border-color);
        }
    }

    .action-button-div {
        hr {
            border: 1px solid var(--border-color) !important;
            margin: 5px 0 !important;
        }
    }
}

.add-customer-modal {
    overflow: hidden;

    // position: relative;
    .modal-dialog {
        width: 100%;
        // max-width: 60%;
        max-width: 40%;

        .modal-content {
            max-height: calc(100vh - 2rem);
            // overflow: auto;

            .modal-title {
                font-size: 1rem;
                font-weight: 700;
            }

            .modal-header {
                padding: 1rem 2rem;
            }

            .modal-body {
                height: 100%;
                padding: 1rem 2rem;
                overflow: auto;

                .customer-form-fields {
                    @include displayColumnCenter;

                    .customer-form-fields-image-div {
                        @include displayColumnCenter;
                    }

                    label {
                        padding-bottom: 5%;
                        padding-top: 5%;
                        font-size: 0.8rem;
                        color: var(--modal-label-color);
                        font-weight: 400;
                    }

                    .col-md-8 label {
                        padding: 2% 0 3% 0;
                    }

                    .col-md-12 label {
                        padding: 1% 0 1% 0;
                    }

                    .customer-contact-fields-div {
                        display: flex;
                        align-items: center;
                        gap: 2%;

                        // .customer-contact-select {
                        //     max-width: max-content;
                        //     min-width: min-content;
                        // }
                        .react-select-container {
                            width: 125px !important;

                            &:focus,
                            &:focus-within {
                                width: 125px !important;
                            }
                        }

                        .css-1xc3v61-indicatorContainer {

                            &:focus,
                            &:focus-within {
                                padding: 4px !important;
                            }
                        }

                        .css-15lsz6c-indicatorContainer {
                            padding: 4px !important
                        }

                        .react-select-container .css-hlgwow {
                            padding: 0px 4px !important;
                        }
                    }

                    .business-unit-btn {
                        @include displayFlexCenter;
                        padding: 2%;
                    }

                    .view-sla-data {
                        display: grid;

                        span {
                            padding-left: 0.5rem;
                            font-size: 0.8rem;
                        }

                        .description-text {
                            word-break: break-word;
                            white-space: normal;
                        }
                    }

                    .customer-company-code-div {
                        display: flex;
                        align-items: center;
                        gap: 5px;

                        img {
                            width: 25px;
                            height: 25px;
                        }
                    }

                    .score-details-data {
                        display: grid;
                        grid-template-columns: 38% 2% 60%;
                        align-items: center;
                        padding: 2% 0%;
                    }
                }

            }

            .modal-footer {
                padding: 0.5rem 2rem;
            }
        }
    }

    .wq-modal-table-container {
        border: 1px dotted var(--next-btn-bg-color);
        margin-top: 0.5rem;
        padding: 1rem;

        .add-wqRules-table-div {
            .add-wqRules-table-container {
                max-height: 20vh !important;
                min-height: 20vh !important;
                padding-top: 0.5rem;
            }
        }

    }

    .cross-delete-img-div {
        @include displayFlexCenterEnd;

        .cross-delete-img {
            height: 20px;
            width: 20px;
            border: 1px solid;
            border-radius: 50%;
            padding: 2px;
            cursor: pointer;
        }
    }
}

// .add-customer-modal {
//     .modal-dialog {
//         width: 100%;
//         max-width: 60%;
//     }
// }

.add-customer-menus {
    padding: 0% 5%;
    position: relative;

    .add-customer-menus-names {
        display: flex;
        align-items: center;
        flex-direction: column;

        img {
            position: relative;
            height: 40px;
            width: 40px;
            border-radius: 50%;
            padding: 0.5rem;
        }

        &.step-active img {
            background-color: rgba(58, 201, 119, 1);
            /* Active step color */
        }

        &.step-next img {
            background-color: rgba(6, 201, 244, 1);
            /* Next step color */
        }

        &.step-remaining img {
            background-color: rgba(217, 217, 217, 1);
            /* Remaining steps color */
        }

        p {
            text-align: center;
            text-wrap: nowrap;
        }
    }

    .add-customer-dots {
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            position: absolute;
            top: 10%;
            // left: 22%;
        }
    }
}

.add-customer-calendar-container {
    position: relative;
    display: flex;
    flex-direction: column;


    img {
        position: absolute;
        right: 5%;
        bottom: 10%;
    }

}

.item-list {
    max-height: 225px;
    overflow-y: auto;
    border: 1px solid var(--filter-items-border-color);
    padding: 10px;
    border-radius: 5px;
    background-color: var(--table-header-color);
}

.display-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1px 0;
    // border-bottom: 1px solid #ddd;
}

.display-item button {
    background: none;
    border: none;
    color: var(--status-inactive-text-color);
    cursor: pointer;
}

.value-filter-modal {
    .modal-dialog {
        max-width: 55%;
    }

    .value-filter-btn-div {
        @include displayFlex2Start;
        gap: 1%;
    }

    .customer-value-filter-checkbox-group {
        @include displayFlex2Start;
        gap: 8%;
    }

    .value-filter-select-div {
        padding-right: 0;
    }

    .value-filter-text-add-div {
        display: flex;
        align-items: flex-end;
        gap: 3%;
    }

    .value-filter-radio-btn-div {
        @include displayFlex2Start;
        gap: 6%;
    }

    .filter-textarea {
        display: flex;
        border: 1px solid var(--modulename-border-color);
        border-radius: 5px;
        padding: 8px;
        min-height: 100px;
        font-size: 0.85rem;

        .filter-delete-img {
            padding: 1px;
            height: 15px;
            width: 15px;
            border-radius: 50%;
            border: 1px solid var(--header-border-color);
            margin: 4px 4px 4px 0px;
        }
    }
}

// CustomerViewDetails css
.customermanagement-view-details-btns-container {
    border: 1px solid var(--modulename-border-color);
    padding: 1rem;
    border-radius: 0.8%;
    background-color: var(--user-profile-bg-color);
    margin: 0rem !important;

    .customermanagement-view-details-first-col {
        border-right: 2px solid var(--modulename-border-color);

        .customermanagement-view-details-header {
            text-align: center;

            p {
                color: var(--next-btn-bg-color);
                font-weight: 700;
            }
        }

        .head-quarters-list-items .list-group-item {
            display: grid;
            // grid-template-columns: 120px 8px minmax(0, 1fr);
            grid-template-columns: 130px 8px minmax(0, 1fr);
            border: none;
            padding: 0;
            word-wrap: break-word;

            p {
                &:first-of-type {
                    color: var(--previous-btn-text-color);
                    font-weight: 400;
                }

                &:last-of-type {
                    color: var(--view-page-text-color);
                    font-weight: 400;
                    white-space: normal;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }
    }

    /* General tab styling */
    .nav-tabs .nav-link {
        color: var(--balance-count-bg-color);
        // background-color: var(--dashboard-bg-color);
        background-color: rgba(52, 58, 64, 0.1);
        font-weight: 700;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    }

    /* Styling for the active tab */
    .nav-tabs .nav-link.active {
        color: var(--sidenavbar-text-color);
        background-color: var(--sidenavbar-color);
        font-weight: 700;
    }

    .customermanagement-view-accordian-header {
        .accordion-button {
            color: var(--balance-count-bg-color);
            font-weight: 600;
        }

        .accordion-button:focus {
            box-shadow: none !important;
        }

        .accordion-button:not(.collapsed) {
            background-color: var(--next-btn-text-color);
        }
    }

    .customermanagement-tab-accordion {
        p {
            margin-bottom: 3px;
        }

        .accordion-item {
            border-right: none;
            border-left: none;
        }

        .accordion-body {
            background-color: var(--accordion-body-color) !important;
        }
    }

    .customermanagement-view-details-second-header {
        p {
            color: var(--next-btn-bg-color);
            font-weight: 700;
            text-align: center;
        }
    }

    .customermanagement-accordian-body {
        p {
            color: var(--previous-btn-text-color);
        }
    }
}

// CustomerViewDetails css

/* Custom styles for the accordion header */
.custom-accordion-header {
    background-color: #fff;
    /* White background */
    color: #333;
    /* Dark text color */
    font-weight: bold;
    border: 1px solid #ddd;
    /* Light grey border */
    border-radius: 5px;
    /* Rounded corners */
    padding: 10px 15px;
    /* Padding inside the header */
    display: flex;
    /* Flexbox to align arrow and text */
    align-items: center;
    justify-content: space-between;
    /* Space between text and arrow */
    transition: background-color 0.3s ease;
    cursor: pointer;
    /* Pointer cursor on hover */
}

/* Custom arrow styles */


.accordion-button::after {
    flex-shrink: 0;
    width: 1.5rem !important;
    height: 1.5rem !important;
    margin-left: auto;
    content: "";
    background-image: url('../../assets/images/CustomerManagement/accordionImg.svg') !important;
    background-repeat: no-repeat;
    background-size: 1.5rem !important;
    transition: transform 0.2s ease-in-out;
}

.accordion-button:not(.collapsed)::after {
    background-image: url('../../assets/images/CustomerManagement/accordionImg.svg') !important;
    transform: rotate(-180deg);
}

// Role Add Modal Css
.add-role-modal {
    .modal-dialog {
        width: 100%;
        // max-width: 60%;
        max-width: 40%;

        .modal-content {
            max-height: calc(100vh - 2rem);
            // overflow: auto;

            .modal-title {
                font-size: 1rem;
                font-weight: 700;
            }

            .modal-header {
                padding: 1rem 1rem;
            }
        }

        .modal-body {
            padding-top: 2px;
            overflow: auto;

            label {
                padding-bottom: 5%;
                padding-top: 5%;
                font-size: 0.8rem;
                color: var(--modal-label-color);
                font-weight: 400;
            }

            .add-role-table-container {
                max-height: 58vh !important;
                min-height: 58vh !important;
            }
        }
    }
}

.add-leave-modal {

    .modal-dialog {
        width: 100%;
        // max-width: 60%;
        max-width: 60%;

        .modal-content {
            max-height: calc(100vh - 2rem);
            // overflow: auto;

            .modal-title {
                font-size: 1rem;
                font-weight: 700;
            }

            .modal-header {
                padding: 1rem 1rem;
            }
        }

        .modal-body {
            padding-top: 2px;

            label {
                padding-bottom: 5%;
                padding-top: 5%;
                font-size: 0.8rem;
                color: var(--modal-label-color);
                font-weight: 400;
            }

            .add-role-table-container {
                max-height: 58vh !important;
                min-height: 58vh !important;
            }
        }
    }
}

.add-rest-data-source-modal {
    .modal-dialog {
        width: 100%;
        max-width: 60%;

        .modal-content {
            // max-height: calc(100vh - 2rem);
            // overflow: auto;
            height: 90vh; // Fixed height for consistency
            max-height: 90vh; // Prevent overflowing the viewport

            .modal-title {
                font-size: 1rem;
                font-weight: 700;
            }

            .modal-header {
                padding: 1rem 1rem;
            }
        }

        .modal-body {
            padding-top: 2px;
            overflow: auto; // Enable scrolling for content that exceeds the height

            label {
                // padding-bottom: 5%;
                // padding-top: 5%;
                font-size: 0.8rem;
                color: var(--modal-label-color);
                font-weight: 400;
            }

            .add-role-table-container {
                max-height: 58vh !important;
                min-height: 58vh !important;
            }
        }
    }
}


// Role Add Modal Css
.customermanagement-table-div {
    .assignTo-img {
        width: 45px;
        height: 25px;
        background-color: var(--over-all-count-bg-color);
        border-radius: 5%;
        padding: 0.3rem;
        cursor: pointer;
    }

    .run-query-img {
        width: 65px;
        height: 25px;
        background-color: var(--assignTo-bg-color);
        border-radius: 5%;
        padding: 0.2rem;
        display: flex;
        justify-content: space-around;

        p {
            font-style: italic;
            font-weight: 600;
        }
    }

    .sloc-div {
        background-color: var(--table-header-color);
        padding: 0.3rem;
        border-radius: 0.3rem;
        cursor: pointer;
    }
}

// @media (max-width: 768px)
@media (min-width: 768px) and (max-width: 1023px) {
    // .lm-top-bar {
    //     margin-left: 8vw !important;
    //     width: calc(100% - 8vw) !important;

    //     &.clicked {
    //         margin-left: 31.5vw !important;
    //         width: calc(100% - 31.5vw) !important;
    //     }
    // }

    // .lm-sidevar {
    //     .container {
    //         width: 8vw !important;
    //     }

    //     .button {
    //         height: 3rem !important;
    //     }
    // }

    // .lm-sidevar {
    //     .container {
    //         &.clicked {
    //             width: 31.5vw !important;
    //         }
    //     }
    // }

    // .lm-main-body {
    //     margin-left: 8vw !important;
    //     width: calc(100% - 8vw) !important;

    //     &.clicked {
    //         margin-left: 31.5vw !important;
    //         width: calc(100% - 31.5vw) !important;
    //     }
    // }

    // .slick-bar {
    //     .text {
    //         font-size: 0; // default padding
    //     }

    //     &.clicked {
    //         .text {
    //             font-size: 0.8rem; // padding when .slick-bar has the 'clicked' class
    //         }
    //     }
    // }



    .lm-top-bar {
        margin-left: 31.5vw !important;
        width: calc(100% - 31.5vw) !important;

        &.clicked {
            margin-left: 8vw !important;
            width: calc(100% - 8vw) !important;
        }
    }

    .lm-sidevar {
        .container {
            width: 31.5vw !important;
        }

        .button {
            height: 3rem !important;
        }
    }

    .lm-sidevar {
        .container {
            &.clicked {
                width: 8vw !important;
            }
        }
    }

    .lm-main-body {
        margin-left: 31.5vw !important;
        width: calc(100% - 31.5vw) !important;

        &.clicked {
            margin-left: 8vw !important;
            width: calc(100% - 8vw) !important;
        }
    }

    .slick-bar {
        width: 14rem !important;

        .text {
            font-size: 0.8rem; // default padding
            overflow: visible;
            margin-left: 0.9rem;
        }

        &.clicked {
            width: 3.5rem !important;

            .text {
                font-size: 0rem; // padding when .slick-bar has the 'clicked' class
            }
        }
    }

    .company-logo {
        display: block !important;
    }

    .company-small-logo {
        display: none !important;
    }

    .container.clicked .company-logo {
        display: none !important;
    }

    .container.clicked .company-small-logo {
        display: block !important;
    }

    .customermanagement-btns-container {
        @include displayColumnStartCenter;

        .customermanagement-backbtn-div {
            width: 100%;
        }

        .customermanagement-search-filter {
            padding-top: 2%;
            width: 100%;
            justify-content: flex-start !important;

            .filters-col {
                width: 38.5% !important;
                position: relative;

                .react-datepicker-wrapper {
                    width: 100% !important;
                }
            }
        }
    }

    .components-download-container {
        .download-btn-container {
            @include displayColumnStartCenter;
            justify-content: flex-start;
            padding: 12vh 1rem 0.6vh;

            .backbtn-div {
                width: 100%;
                padding: 0;
            }

            .download-header-btns {
                width: 100%;
                padding-top: 0.5rem !important;

                .header-btns {
                    margin: 0;
                }
            }
        }
    }

    .dashboard-cards-container {
        .dashboard-cards-cols {
            width: 50%;
            padding: 0px 10px 10px 0px !important;
        }
    }

    .dashboard-charts {
        .dashboard-charts-div {
            width: 100% !important;

            .relationship-type-chart-header-div {
                @include displayFlexBetween;
            }

            .relation-type-chart-container {
                .customer-status-chart {
                    padding: 0 !important;
                }
            }

            .charts-container {
                padding-bottom: 0.8rem !important;

                .highchart-container {
                    width: 290px !important;
                }
            }
        }

        .attendance-status-container {
            flex-direction: column !important;
            align-items: flex-start !important;
        }
    }

    .add-customer-modal .modal-dialog {
        width: 100%;
        max-width: 80% !important;
    }

    .user-profile-form-container {
        flex-direction: column !important;
        padding: 1rem 1rem 5rem 0rem !important;

        .user-profile-form-first-col {
            width: 100% !important;
        }

        .user-profile-form-second-col {
            width: 100% !important;

            .user-profile-form-row {
                justify-content: center !important;
                align-items: center !important;

                .user-profile-form-col {
                    width: 70% !important;
                }
            }
        }
    }

    .attendence-leaves-chart-div {
        flex-direction: column !important;

        .attendence-leaves-chart-col-div {
            width: 100% !important;
        }

        .attendence-leaves-calender-container {
            width: 100% !important;

            .holiday-schedule-card {
                width: 100% !important;
            }
        }
    }

    .leaves-cards-container {
        gap: 10px !important;

        .leave-details-card {
            flex: 1 1 45% !important;
        }

        .leave-cards {
            flex: 1 1 45% !important;
        }
    }

    .employee-leaves-details-container {
        flex-direction: column !important;

        .customermanagement-search-filter {
            width: auto !important;
        }

        .employee-profile-col {
            width: auto !important;
        }
    }

    .dashboard-library-col {
        flex: 0 0 100% !important;
    }
}

@media (min-width: 1024px) and (max-width: 1279px) {
    .customermanagement-btns-container {
        @include displayFlexCenterStart;

        .customermanagement-backbtn-div {
            width: 100%;
        }

        .customermanagement-search-filter {
            display: flex;
            // flex-direction: column;
            justify-content: flex-start !important;
            align-items: baseline !important;
            padding-top: 2%;
            width: 100%;
        }
    }
}