.customer-management-container {

    .dashboard-library-container {
        display: flex;
        flex-wrap: wrap;
        // justify-content: space-between;
        margin: 0;
        // padding: 1.5rem;
        padding: 0 1.5rem;
        gap: 20px;
    }

    .dashboard-library-col {
        flex: 0 0 calc(25% - 20px);
        border: 1px solid var(--modulename-border-color);
        border-radius: 5px;
        padding: 10px;
        background: var(--user-profile-text-color);
        transition: all 0.3s ease;

        &:hover {
            transform: scale(1.05);
            box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
        }
    }

    .dashboard-library-div {
        display: flex;
        justify-content: space-between;
        align-items: center;

        p {
            margin: 0;
            font-size: 1.1rem;
            font-weight: bold;
            color: var(--next-btn-text-color);
        }

        img {
            width: 16px;
            height: 16px;
        }
    }

    .dashboard-library-img {
        text-align: center;
        margin-top: 10px;
        position: relative;

        .library-img {
            width: 100%;
            height: auto;
            object-fit: contain;
            cursor: pointer;
        }

        .content-icon {
            position: absolute;
            bottom: 6px;
            right: 6px;
            width: 30px;
            height: 30px;
            background-color: var(--buttons-bg-color);
            border-radius: 50%;
            padding: 5px;
        }
    }

    hr {
        border-top: 1px solid var(--next-btn-text-color);
        margin: 10px 0;
    }
}

.dashboard-library-tooltip {
    // inset: auto auto 0px -100px !important;

    .tooltip-arrow {
        // transform: translate(213px, -5px) !important;
    }

    .tooltip-arrow::before {
        border-top-color: var(--buttons-bg-color) !important;
    }

    .tooltip-inner {
        background-color: var(--buttons-bg-color) !important;
        max-width: 250px !important;
    }
}

// ShareModal
.share-search {
    position: relative;

    img {
        position: absolute;
        top: 0;
        right: 0;
        width: 35px;
        height: 35px;
        background-color: var(--buttons-bg-color);
        padding: 8px;
        border-radius: 0px 5px 5px 0px;
    }
}

.customer-form-fields {
    .btn {
        // background-color: var(--buttons-bg-color);
        background-color: var(--over-all-count-bg-color);
        color: var(--buttons-bg-color);
        font-weight: 500;
        border: none;
        border-radius: 0.3rem;
        margin-right: 1%;

        &:focus {
            box-shadow: none;
            background-color: var(--buttons-bg-color);
            color: var(--filter-section-bg-color);
        }

        &:hover,
        &:active {
            background-color: var(--buttons-bg-color) !important;
            color: var(--filter-section-bg-color) !important;

            img {
                filter: brightness(0) invert(1);
            }
        }

        img {
            filter: invert(51%) sepia(99%) saturate(436%) hue-rotate(144deg) brightness(103%) contrast(99%);
        }
    }
}

// ShareModal

.powerbi-container {
    width: 100%;
    height: 89vh; // Default height for large screens
}

.powerbi-container iframe {
    width: 100%;
    height: 100%; // Ensures the iframe takes full height of the container
    border: none;
}

// Responsive Adjustments
@media screen and (max-width: 1440px) {
    .powerbi-container {
        height: 85vh;
    }
}

@media screen and (max-width: 1280px) {
    .powerbi-container {
        height: 83vh;
    }
}

@media screen and (max-width: 1024px) {
    .powerbi-container {
        height: 75vh;
    }
}

@media screen and (max-width: 768px) {
    .powerbi-container {
        height: 70vh;
    }
}

@media screen and (max-width: 600px) {
    .powerbi-container {
        height: 65vh;
    }
}

@media screen and (max-width: 480px) {
    .powerbi-container {
        height: 60vh;
    }
}
