@import './SideBar/sidebar.scss';
@import './Login/Signin.scss';

.lm-main-body {
    transition: all 0.35s ease;
}

.lm-top-bar {
    position: fixed;
    z-index: 3;
    transition: all 0.35s ease;
    top: 0;
    height: 6vh;
    // background-color: var(--primary-bg-color);
    background-color: var(--sidenavbar-color);

    .header-container {
        @include displayFlexCenterEnd;
        gap: 2rem;
        padding: 3px;
        border-bottom: 1px solid var(--header-border-color);

        .settings-dropdown {
            .dropdown-toggle {
                &::after {
                    display: none;
                }

                &:focus {
                    box-shadow: none !important;
                }
            }

            .btn {
                text-decoration: none !important;
                padding: 0 !important;
            }
        }


        .header-profile-div {
            display: flex;
            align-items: center;
            gap: 1rem;
            margin-right: 2rem;
        }

        .header-user-name {
            @include displayColumnStartCenter;
            color: var(--sidenavbar-text-color);

            span {
                color: var(--sidenavbar-text-color);
                font-weight: 500;
                font-size: 0.9rem;
            }

            p {
                margin: 0;
                color: var(--sidenavbar-text-color) !important;
                font-weight: 500;
                font-size: 0.8rem !important;
            }
        }
    }
}

// .lm-top-bar-download {
//     position: fixed;
//     z-index: 2;
//     transition: all 0.35s ease;
//     top: 49px;
//     height: 6vh;
//     background-color: var(--sidenavbar-text-color);

.dashboard-btns-container {
    padding: 11px 0px;
    margin: 0;
}

.components-download-container {
    position: sticky;
    top: 0px;
    z-index: 2;

    .download-btn-container {
        @include displayFlexBetween;
        padding: 6vh 1rem 0.6vh;
        background-color: var(--sidenavbar-color);
        border: 1px solid var(--header-border-color);

        .backbtn-div {
            display: flex;
            align-items: center;

            span {
                color: var(--sidenavbar-text-color);
                font-weight: 600;
            }

            .back-navigation-div {
                @include displayFlexCenter;
                cursor: pointer;
            }
        }

        .header-btns {
            @include displayFlexCenterEnd;
            margin-right: 3%;

            .btn {
                // background-color: var(--buttons-bg-color);
                background-color: var(--over-all-count-bg-color);
                color: var(--buttons-bg-color);
                font-weight: 600;
                // border: none;
                // border: 1px solid var(--buttons-bg-color) !important;
                border-radius: 0.3rem;
                margin-right: 1%;

                &:focus {
                    box-shadow: none;
                    background-color: var(--buttons-bg-color);
                    color: var(--filter-section-bg-color);
                }

                &:hover,
                &:active {
                    background-color: var(--buttons-bg-color) !important;
                    color: var(--filter-section-bg-color) !important;

                    img {
                        filter: brightness(0) invert(1);
                    }
                }
            }

            .download-btn {
                color: var(--buttons-bg-color);
                // color: var(--filter-section-bg-color);
            }

            .dropdown-toggle::after {
                display: none;
            }

            img {
                filter: invert(51%) sepia(99%) saturate(436%) hue-rotate(144deg) brightness(103%) contrast(99%);
            }
        }
    }
}

.settings-header-container {
    padding: 1rem 2rem;
    margin: 0;

    .settings-backbtn-div {
        padding: 0;
        @include displayFlexCenterStart;
        cursor: pointer;
    }
}

// }

.lm-footer {
    position: fixed;
    z-index: 3;
    transition: all 0.35s ease;
    bottom: 0;
    height: 12vh;
    background-color: var(--primary-bg-color);
}

.page-wrapper {
    .main-body {
        width: auto;
        position: relative;
        margin-left: auto;
        right: 0px;
        // padding: 12vh 1rem;
        // padding: 14vh 1.5rem 10vh;
        min-height: 100vh;
        transition: all 0.35s ease;
        transform: translateX(0);
        background-color: var(--sidenavbar-color);

        &.toggle-shift-left {
            width: calc(100% - 6vw);
            position: relative;
            right: 0;
            margin-left: auto;
            transition: all 0.35s ease;
            transform: translateX(0);
        }
    }
}

// region css for form text inputs
.form-control {
    color: var(--primary-text-color);
    font-size: 0.875rem !important;
    font-weight: 500;
    // border: none;
    // box-shadow: var(--3d-shadow-effect);
    border: 1px solid var(--border-color);

    &:focus {
        outline: none !important;
        // box-shadow: var(--3d-shadow-effect-focus) !important;
        box-shadow: none !important;
        border: 1px solid var(--border-color) !important;
    }
}

@media (min-width: 1024px) and (max-width: 1440px) {
    .form-control {
        font-size: 0.75rem;
    }
}

// endregion 

// region pharagraph font default size
p,
button {
    font-size: 0.875rem !important;
}

p {
    color: var(--primary-text-color);
}

// endregion

.react-datepicker__input-container input {
    width: 100%;
    border: 1px solid var(--border-color) !important;
    border-radius: 0.375rem !important;
    // padding: 3.5% 6%;
    padding: 0.375rem 0.75rem;
    font-size: 0.85rem !important;
}

.react-datepicker-wrapper {
    width: 100% !important;
}

.react-datepicker-popper {
    z-index: 10 !important;
}

@media (min-width: 1024px) and (max-width: 1440px) {
    .react-datepicker__input-container input {
        font-size: 0.75rem;
        // padding: 4%;
    }
}

.select .from-date input {
    &:focus {
        outline: none;
        border: 1px solid var(--border-color) !important;
        box-shadow: none !important;
    }
}

.react-datepicker-wrapper .react-datepicker__input-container input {
    &:focus {
        outline: none;
        border: 1px solid var(--border-color) !important;
        box-shadow: none !important;
    }
}

.customermanagement-table-div {
    table {
        thead {
            tr {
                z-index: 1;
            }
        }
    }
}

table {
    thead {
        tr {
            background-color: var(--table-header-color);
            position: sticky;
            top: 0;
            // z-index: 1;

            th {
                padding: 10px 13px !important;
                font-size: 0.8rem !important;
            }

            .table-header-div {
                color: var(--next-btn-bg-color);
                font-weight: 600;
            }
        }
    }

    tbody {
        tr {
            td {
                padding: 8px 13px !important;
                font-size: 0.8rem !important;
            }
        }
    }
}

.table-row-data-container:hover {
    background-color: var(--table-data-bg-color);
}

.clicked-value {
    background: rgba(52, 58, 64, 0.1);
}

.css-1uzl8r8-MuiPaper-root-MuiTableContainer-root {
    border-radius: 0px !important;
}

body::-webkit-scrollbar,
.table-container::-webkit-scrollbar {
    width: 4px;
    height: 4px;
}

body::-webkit-scrollbar-thumb,
.table-container::-webkit-scrollbar-thumb {
    background-color: var(--scroller-bg-color);
    border-radius: 4px;
}

body::-webkit-scrollbar-track,
.table-container::-webkit-scrollbar-track {
    background-color: var(--table-header-color);
}

.MuiTableContainer-root {
    max-height: 72vh !important;
    min-height: 72vh !important;
}

/* Scrollbar styles for the modal */
.modal-content::-webkit-scrollbar {
    width: 3px !important;
    height: 3px !important;
}

.modal-content::-webkit-scrollbar-thumb {
    background-color: var(--scroller-bg-color) !important;
    border-radius: 4px !important;
}

.modal-content::-webkit-scrollbar-track {
    background-color: var(--table-header-color) !important;
}

.modal-header .btn-close {
    --bs-btn-close-bg: url('../images/UserManagement/modal-close-img.svg');
    opacity: 1;

    &:focus {
        box-shadow: none;
    }
}

.status-badge {
    padding: 8px 12px;
    color: #fff;
    border-radius: 5px;
    width: 105px;
    display: flex;
    font-weight: 500;
    font-size: 13px;
    align-items: center;
    justify-content: center;
}

.status-badge-for-enabling {
    display: inline-flex;
    align-items: center;
    padding: 0px 0px 0px 8px;
    font-weight: 500;
    font-size: 13px;
    border-radius: 4px;
    background-color: var(--status-active-bg-color);
    color: var(--status-active-text-color);
    transition: background-color 0.3s ease;
}

.status-badge-for-enabling .dropdown-arrow {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 25px;
    height: 30px;
    background-color: #e0e0e0;
    /* Light gray background for the arrow section */
    border-radius: 0 4px 4px 0;
    /* Rounded corners on the right side */
    margin-left: 8px;
    /* Space between text and arrow */
}

.status-badge-for-enabling img {
    width: 10px;
    /* Smaller arrow icon size */
    height: auto;
}

.status-badge-for-priority {
    background-color: var(--balance-count-bg-color);
    color: var(--user-profile-bg-color);
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 30px;
    width: 60px;
}

.no-dropdown-badge {
    justify-content: center !important;
}

.complete-status {
    background-color: var(--status-active-bg-color);
    color: var(--status-active-text-color);
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 30px;
    width: 105px;
}

.cancel-status {
    background-color: var(--status-inactive-bg-color);
    color: var(--status-inactive-text-color);
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 30px;
    width: 105px;
}

.expired-row {
    background-color: var(--status-inactive-bg-color);
}

.pending-status {
    background-color: var(--status-pending-bg-color);
    color: var(--status-pending-text-color);
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 30px;
    width: 105px;
}

.inprogress-status {
    background-color: var(--status-inprogress-bg-color);
    color: var(--status-inprogress-text-color);
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 30px;
    width: 105px;
}

.enabling-dropdown-toggle::after {
    display: none;
}

.dashboard-library-dropdown {
    .dropdown-toggle::after {
        display: none;
    }
}

.invalid-feedback {
    font-size: 0.6rem !important;
}

@media (min-width: 1024px) and (max-width: 1440px) {
    table {
        thead {
            tr {
                th {
                    font-size: 0.8rem !important;
                }
            }
        }

    }

    .nav-tabs {
        font-size: 0.75rem;
    }
}

.add-single-bulk {
    .btn-add-save-update {
        background-color: var(--over-all-count-bg-color) !important;
        color: var(--buttons-bg-color) !important;
        font-weight: 500;

        &:hover {
            background-color: var(--buttons-bg-color) !important;
            color: var(--filter-section-bg-color) !important;
        }

        &:focus-visible {
            background-color: var(--buttons-bg-color) !important;
            color: var(--filter-section-bg-color) !important;
            box-shadow: none;
            outline: none;
        }

        &:active {
            background-color: var(--buttons-bg-color) !important;
            color: var(--filter-section-bg-color) !important;
        }

        .attachment-note {
            font-size: 0.75rem;
        }
    }

    .btn-sb-cancel {
        background-color: var(--cancel-btn-bg-color) !important;
        color: var(--cancel-btn-text-color) !important;
        font-weight: 500;

        &:hover {
            background-color: var(--cancel-btn-text-color) !important;
            color: var(--cancel-btn-bg-color) !important;
        }

        &:focus-visible {
            background-color: var(--cancel-btn-text-color) !important;
            color: var(--cancel-btn-bg-color) !important;
            box-shadow: none;
            outline: none;
        }

        &:active {
            background-color: var(--cancel-btn-text-color) !important;
            color: var(--cancel-btn-bg-color) !important;
        }
    }

    .btn-add-dropdown {
        background-color: var(--over-all-count-bg-color) !important;
        color: var(--buttons-bg-color);
        font-weight: 500;
        width: max-content !important;

        &:hover,
        &:active,
        &:focus {
            background-color: var(--buttons-bg-color) !important;
            color: var(--filter-section-bg-color) !important;

            img {
                filter: brightness(0) invert(1);
            }
        }

        &:focus-visible {
            background-color: var(--buttons-bg-color) !important;
            color: var(--filter-section-bg-color);
            box-shadow: none;
            outline: none;

            img {
                filter: brightness(0) invert(1);
            }
        }

        // &:active {
        //     background-color: var(--buttons-bg-color);
        //     color: var(--filter-section-bg-color);

        //     img {
        //         filter: brightness(0) invert(1);
        //     }
        // }

        img {
            filter: invert(51%) sepia(99%) saturate(436%) hue-rotate(144deg) brightness(103%) contrast(99%);
        }
    }

    .btn-previous {
        background-color: var(--previous-btn-bg-color) !important;
        color: var(--previous-btn-text-color) !important;
        font-weight: 500;

        &:hover {
            background-color: var(--previous-btn-text-color) !important;
            color: var(--previous-btn-bg-color) !important;
        }

        &:focus-visible {
            background-color: var(--previous-btn-text-color) !important;
            color: var(--previous-btn-bg-color) !important;
            box-shadow: none;
            outline: none;
        }

        &:active {
            background-color: var(--previous-btn-text-color) !important;
            color: var(--previous-btn-bg-color) !important;
        }
    }

    // .status-btn {
    //     background-color: var(--status-pending-bg-color) !important;
    //     color: var(--status-pending-text-color) !important;
    //     font-weight: 500;

    //     &:hover {
    //         background-color: var(--status-pending-bg-color) !important;
    //         color: var(--status-pending-text-color) !important;
    //     }

    //     &:focus-visible {
    //         background-color: var(--status-pending-bg-color) !important;
    //         color: var(--status-pending-text-color) !important;
    //         box-shadow: none;
    //         outline: none;
    //     }

    //     &:active {
    //         background-color: var(--status-pending-bg-color) !important;
    //         color: var(--status-pending-text-color) !important;
    //     }
    // }


    .btn-approval-dropdown {
        background-color: var(--over-all-count-bg-color) !important;
        color: var(--buttons-bg-color) !important;
        font-weight: 500;

        &:hover {
            background-color: var(--previous-btn-bg-color) !important;
            color: var(--filter-section-bg-color) !important;
        }

        &:focus-visible {
            background-color: var(--previous-btn-bg-color) !important;
            color: var(--filter-section-bg-color) !important;
            box-shadow: none;
            outline: none;
        }

        &:active {
            background-color: var(--previous-btn-bg-color) !important;
            color: var(--filter-section-bg-color) !important;
        }
    }

    .btn-advance {
        background-color: var(--table-header-color) !important;
        color: var(--next-btn-bg-color) !important;
        font-weight: 500;

        &:hover {
            background-color: var(--table-header-color) !important;
            color: var(--next-btn-bg-color) !important;
        }

        &:focus-visible {
            background-color: var(--table-header-color) !important;
            color: var(--next-btn-bg-color) !important;
            box-shadow: none;
            outline: none;
        }

        &:active {
            background-color: var(--table-header-color) !important;
            color: var(--next-btn-bg-color) !important;
            border: 1px solid var(--sidenavbar-text-color) !important;
        }
    }

    .single-bulk-upload {
        transform: translate(0px, 39px) !important;
        margin-left: 0px !important;
        margin-top: 0px !important;
        border-radius: 5px !important;
        // background-color: $blackColor;
        min-width: max-content !important;
        // font-size: $fz13 !important;

        li {
            button {
                // color: $whiteColor;

                &:hover {
                    // color: $secondaryColor;
                }
            }
        }

        // .dropdown-item:focus,
        // .dropdown-item:active,
        // .dropdown-item:hover {
        //     background-color: transparent !important;
        // }
    }

    .btn:focus {
        box-shadow: none !important;
        border: none !important;
    }

    .dropdown-menu {
        .rejected {
            color: var(--status-inactive-text-color) !important;
        }

        .approved {
            color: var(--status-active-text-color) !important;
        }

        .pending {
            color: var(--status-pending-text-color) !important;
        }
    }
}

.attachment-section {
    width: 100% !important;

    .attachment-btn {
        width: 100% !important;
    }

    .download-document-btn {
        @include displayFlexBetween;
        padding: 0;
        border: none;
        background-color: var(--over-all-count-bg-color);
        color: var(--buttons-bg-color);
        width: 100%;
        border-radius: 5px 0px 0px 5px;

        .download-img {
            height: 50px !important;
            width: 50px !important;
            padding: 0.7rem !important;
            background-color: var(--buttons-bg-color) !important;
        }
    }
}

// Dropdown css
.dropdown-item:active {
    // color: var(--next-btn-text-color) !important;
    background-color: var(--previous-btn-bg-color) !important;
}

// Dropdown css

// search css for fileter
.customermanagement-filter-search {
    position: relative;
    padding-bottom: 2%;

    img {
        position: absolute;
        width: 30px;
        height: 34px;
        background-color: var(--buttons-bg-color);
        top: 0px;
        right: 0px;
        border-radius: 20%;
        padding: 7px;
    }
}

.customer-rest-container {
    .customer-rest-col {
        @include displayFlexCenter;
        padding: 3%;
    }
}

.text-success-color {
    color: var(--tik-mark-color) !important;
    font-weight: 600;
}

// search css for fileter

// multi select dropdown
.rmsc .dropdown-container:focus-within {
    outline: none !important;
    box-shadow: none !important;
    border: 1px solid var(--border-color) !important;
}

.rmsc .dropdown-heading .dropdown-heading-value {
    font-size: 0.85rem !important;
}

.rmsc .dropdown-heading {
    height: 30px !important;
    min-height: 31px !important;
    padding: 7px !important;
}

.rmsc .options {
    padding-left: 0.5rem !important;
}

// multi select dropdown

// select css
/* Ensure the dropdown menu appears above everything */
.react-select__menu {
    z-index: 9999 !important;
    position: absolute !important;
}

/* Ensure the dropdown options are fully visible */
.react-select__menu-list {
    z-index: 9999 !important;
}

.react-select-container {
    // z-index: 2 !important;

    &:focus,
    &:focus-within {
        outline: none !important;
        box-shadow: none !important;
        border: 1px solid var(--border-color) !important;
        border-radius: 0.375rem;
        // height: 33px !important;
        // min-height: 33px !important;
    }

    .css-t3ipsp-control {
        border: none !important;
        box-shadow: none !important;
    }

    .css-13cymwt-control {
        border: 1px solid var(--border-color) !important;
        border-radius: 0.375rem;
        height: 33px !important;
        min-height: 33px !important;
    }

    .react-select__single-value {
        font-size: 0.85rem !important;
    }

    .react-select__control .css-13cymwt-control {
        &:hover {
            border-color: 1px solid var(--border-color) !important;
            /* Change border color on hover */
        }
    }

    .react-select__menu-list {

        .react-select__option {
            font-size: 0.85rem !important;

            &:hover {
                // background-color: var(--table-data-bg-color) !important;
                // color: var(--user-profile-bg-color) !important;
                background-color: rgba(6, 201, 244, 0.2) !important;
            }

            &:active {
                // background-color: var(--previous-btn-bg-color) !important;
                background-color: rgba(6, 201, 244, 0.2) !important;

            }

            &.react-select__option--is-focused {
                // background-color: var(--previous-btn-bg-color) !important;
                background-color: rgba(6, 201, 244, 0.2) !important;

            }

            &.react-select__option--is-selected {
                // background-color: var(--previous-btn-bg-color) !important;
                // color: var(--user-profile-bg-color) !important;
                color: var(--primary-color) !important;
                background-color: rgba(6, 201, 244, 0.2) !important;
            }
        }
    }

    .css-1xc3v61-indicatorContainer {
        padding: 4px !important;
    }

    .css-1jqq78o-placeholder {
        font-size: 0.8rem !important;
    }

    .css-hlgwow {
        padding: 0px 8px !important;
    }

    .react-select__control {

        &:focus,
        &:focus-within {
            min-height: 33px !important;
            height: 33px !important;
        }
    }

    .css-15lsz6c-indicatorContainer {

        &:focus,
        &:focus-within {
            padding: 4px !important;
        }
    }

    .css-9x5mqu {
        font-size: 0.8rem !important;
    }

    .css-15lsz6c-indicatorContainer {
        padding: 4px !important;
    }

    .css-16xfy0z-control {
        min-height: 33px !important;
    }
}

.complaint-status-select {
    z-index: 2 !important;
}

.customer-contact-select {
    min-width: max-content !important;
}


// select css

/* General tab styling */
.nav-tabs .nav-link {
    color: var(--sidenavbar-color);
    background-color: rgba(52, 58, 64, 0.1);
    font-weight: 700;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

/* General tab styling */
.nav-tabs .nav-link {
    color: var(--sidenavbar-text-color);
    background-color: var(--sidenavbar-color);
    font-weight: 700;
    box-shadow: var(--tabs-box-shadow);
}

/* Styling for the active tab */
.nav-tabs .nav-link.active {
    color: var(--user-profile-bg-color) !important;
    background-color: var(--buttons-bg-color) !important;
    font-weight: 700;
}

/* Styling for the hover state */
.nav-tabs .nav-link:hover {
    color: var(--sidenavbar-text-color);
    background-color: var(--sidenavbar-color);
    font-weight: 700;
    box-shadow: var(--tabs-box-shadow);
}

// error message
.text-danger {
    font-size: 0.7rem !important;
}

.text-success {
    font-size: 0.7rem !important;
}

// error message

// required-asterisk
.required-asterisk {
    color: red;
}

// required-asterisk

.pagination-div {
    display: flex;
    flex-direction: row !important;
    padding: 12px 0px;

    // .MuiPagination-root {
    //     margin: 0 !important;
    // }

    // .css-1pi9rcw-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
    //     background-color: var(--buttons-bg-color) !important;
    // }

    // .MuiBox-root {
    //     margin: 0 !important;
    // }

    // .css-1vuolu-MuiFormControl-root {
    //     background-color: var(--buttons-bg-color) !important;
    //     min-width: 65px !important;
    //     border-radius: 4px;
    // }

    // .css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
    //     padding: 3.5px 10px !important;
    // }

    // .css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select {
    //     color: var(--filter-section-bg-color) !important;
    // }

    // .css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input:focus {
    //     box-shadow: none !important;
    // }

    // .css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root:hover {
    //     border: none !important;
    // }

    // .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
    //     border: none !important;
    // }

    .pagination-count {
        margin: 0 !important;

        // .css-1pi9rcw-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
        .Mui-selected {
            background-color: var(--buttons-bg-color) !important;
        }
    }

    .pagination-text-div {
        margin: 0 !important;
    }

    .pagination-select-count {
        background-color: var(--buttons-bg-color) !important;
        color: var(--filter-section-bg-color) !important;
        min-width: 65px !important;
        border-radius: 4px;

        #rows-per-page {
            padding: 3.5px 14px !important;
        }

        // .css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root:hover {
        .css-fvipm8:hover {
            border: none !important;
        }

        // .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
        .MuiOutlinedInput-notchedOutline {
            border: none !important;
        }

        .css-1636szt {
            fill: #fff !important;
        }

        .css-hfutr2-MuiSvgIcon-root-MuiSelect-icon {
            fill: #fff !important;
        }
    }

}

// /* External CSS for Range Slider Thumb */
// .dashboard-progress-bar {
//     input {
//         width: 100%;
//         accent-color: rgb(52, 58, 64);
//         height: 0.2rem;
//     }
// }

.dashboard-progress-bar input[type="range"] {
    /* removing default appearance */
    -webkit-appearance: none;
    appearance: none;
    /* creating a custom design */
    width: 100%;
    cursor: pointer;
    outline: none;
    /*  slider progress trick  */
    overflow: hidden;
    border-radius: 16px;
}

/* Track: webkit browsers */
.dashboard-progress-bar input[type="range"]::-webkit-slider-runnable-track {
    height: 0.5rem;
    background: #ccc;
    border-radius: 16px;
}

/* Track: Mozilla Firefox */
.dashboard-progress-bar input[type="range"]::-moz-range-track {
    height: 0.5rem;
    background: #ccc;
    border-radius: 16px;
}

/* Thumb: webkit */
.dashboard-progress-bar input[type="range"]::-webkit-slider-thumb {
    /* removing default appearance */
    -webkit-appearance: none;
    appearance: none;
    /* creating a custom design */
    height: 0.5rem;
    width: 0.5rem;
    background-color: #fff;
    border-radius: 50%;
    border: 2px solid rgb(52, 58, 64);
    /*  slider progress trick  */
    box-shadow: -407px 0 0 400px rgb(52, 58, 64);
}


/* Thumb: Firefox */
.dashboard-progress-barinput[type="range"]::-moz-range-thumb {
    height: 0.5rem;
    width: 0.5rem;
    background-color: #fff;
    border-radius: 50%;
    border: 1px solid rgb(52, 58, 64);
    /*  slider progress trick  */
    box-shadow: -407px 0 0 400px rgb(52, 58, 64);
}


.dashboard-progress-bar {
    display: flex;
    // align-items: center;
    height: 0.5rem;
    width: 100%;
    background: #fff;
}

.rest-data-stepper .css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-active {
    color: var(--status-active-text-color);
}

.rest-data-stepper .css-10mg1vw-MuiStepper-root {
    padding-top: 1rem;
}

.rest-data-stepper .css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-completed {
    color: var(--buttons-bg-color);
}

.rest-data-get-post-toggle {
    padding: 0.3rem !important;
    font-size: 0.8rem !important;
    border-color: var(--secondary-text-color) !important;
}

.rest-data-get-post-toggle:focus {
    box-shadow: none !important;
    color: var(--primary-bg-color) !important;
    background-color: var(--buttons-bg-color) !important;
}

.btn-check:checked+.btn {
    color: var(--primary-bg-color) !important;
    background-color: var(--buttons-bg-color) !important;
    border: var(--buttons-bg-color) !important;
}

.rest-data-get-post-toggle-btn-group {
    width: 35% !important;
}

.authentication-toggle {
    input {
        border: 1px solid var(--primary-text-color) !important;

        &:focus {
            box-shadow: none;
        }

        &:checked {
            background-color: var(--cancel-btn-text-color) !important;
            outline: none;
            border: none !important;
            box-shadow: none;
        }
    }
}

.rest-data-source-add-page-labels {
    label {
        // padding-bottom: 5%;
        // padding-top: 5%;
        font-size: 0.8rem;
        color: var(--modal-label-color);
        font-weight: 400;
    }
}

.data-source-progressbar-modal {
    @include displayColumnCenter;
    height: 300px;

    .data-source-progressbar {
        width: 100%;
        padding: 1rem;

        .progress-bar {
            background-color: var(--tik-mark-color);
        }
    }
}

@media (min-width: 1024px) and (max-width: 1430px) {
    .lm-top-bar {
        height: 8vh;

        .header-container {
            padding: 0px;

            .header-user-name {
                span {
                    font-weight: 500;
                    font-size: 0.9rem !important;
                }

                p {
                    font-weight: 400;
                    font-size: 0.7rem !important;
                }
            }
        }
    }

    .lm-top-bar-download {
        height: 9vh;

        .dashboard-btns-container {
            padding: 9px 0px;
            margin: 0;
        }
    }

    .components-download-container {
        position: sticky;
        top: 0px;
        z-index: 2;

        .download-btn-container {
            @include displayFlexBetween;
            padding: 8.1vh 1rem 1vh;
            background-color: var(--sidenavbar-color);
            border: 1px solid var(--header-border-color);

            .backbtn-div {
                display: flex;
                align-items: center;
                padding: 0;
            }

            .header-btns {
                @include displayFlexCenterEnd;
                margin-right: 3%;

                .btn {
                    // background-color: var(--buttons-bg-color);
                    border: none;
                    border-radius: 0.3rem;
                    margin-right: 1%;
                    font-size: 0.8rem !important;
                    padding: 0.5rem;

                    &:focus {
                        box-shadow: none;
                        background-color: var(--buttons-bg-color);
                        color: var(--filter-section-bg-color);
                    }
                }

                .download-btn {
                    color: var(--buttons-bg-color);
                }

                .dropdown-toggle::after {
                    display: none;
                }
            }
        }
    }

    .assigned-module-names {
        width: 100% !important;
    }

    .add-single-bulk {
        .btn-add-dropdown {
            .btn-inline-item {
                font-size: 0.8rem !important;

                img {
                    height: 0.8rem !important;
                }
            }
        }
    }

    .add-customer-modal .modal-dialog {
        width: 100% !important;
        max-width: 55% !important;
    }

    .dropdown-item {
        font-size: 0.8rem !important;
    }

    .dropdown-item:hover {
        background-color: var(--previous-btn-bg-color) !important;
    }

    .accordion-body {

        p,
        span {
            font-size: 0.8rem !important;
        }
    }

    .dashboard-library-div {
        p {
            font-size: 0.8rem !important;
        }
    }

    .dashboard-library-img {
        .content-icon {
            width: 25px !important;
            height: 25px !important;
        }
    }

    .dashboard-library-tooltip {
        .tooltip-inner {
            background-color: var(--buttons-bg-color) !important;
            max-width: 250px !important;
            font-size: 0.8rem !important;
        }
    }

    .add-user-submodal {
        width: 35% !important;
        left: 67% !important;

        .modal-dialog {
            width: 100% !important;
            // right: 2% !important;
        }

        .modal-content {
            left: 45%;
            width: 55%;
        }
    }

    .MuiTableContainer-root {
        max-height: 59vh !important;
        min-height: 59vh !important;
    }

    .customer-management-container {
        .customermanagement-content-container {
            padding: 1rem 1.5rem 0.5rem 0rem;
        }
    }

    .pagination-div {
        padding-bottom: 0 !important;
    }

    .leave-cards-counts {
        p {
            font-size: 0.8rem !important;
        }
    }

    .header-menu-options {
        font-size: 0.8rem !important;

        img {
            height: 15px;
        }
    }
}

@media (min-width:1440px) and (max-width: 1919px) {
    .components-download-container {
        .download-btn-container {
            padding: 7.5vh 1rem 0.8vh !important;
        }
    }
}

.customLegend {
    border: 1px solid #e5e5e5;
    padding: 2em 0 1em;
    margin-top: 0.5em;
    position: relative;
    height: 700px;
}

.customLegend legend {
    border: 0;
    background: #fff;
    width: auto;
    transform: translateY(-50%);
    position: absolute;
    top: 0;
    left: 1em;
    padding: 0 .5em;
    height: auto;
    font-size: 0.8rem;
}

.recomended-fit {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 1rem;
}

.form-check-input[type=checkbox] {
    border: 1px solid var(--primary-color);
}

.email-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
}

.verify-email-btn {
    display: inline-block;
    background-color: var(--over-all-count-bg-color) !important;
    color: var(--buttons-bg-color);
    font-size: 10px !important;
    padding: 4px 10px;
    border-radius: 5px;
    text-align: center;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
}

.verify-email-btn:hover {
    background-color: var(--buttons-bg-color) !important;
    color: var(--filter-section-bg-color) !important;
}