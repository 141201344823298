@import "~bootstrap/scss/bootstrap";

:root {
    --primary-color: #2C2C2C;
    --primary-bg-color: #fff;
    --page-bg-color: #f8fbfb;
    // --sidenavbar-color: rgba(52, 58, 64, 1);
    --sidenavbar-color: rgba(245, 245, 245, 1);
    // --sidenavbar-text-color: rgba(255, 255, 255, 1);
    --sidenavbar-text-color: rgba(0, 32, 96, 1);
    --user-profile-bg-color: rgba(255, 255, 255, 1);
    // --dashboard-bg-color: rgba(229, 229, 229, 1);
    --header-border-color: rgba(229, 229, 229, 1);
    --total-customer-img-bg-color: rgba(58, 201, 119, 0.2);
    --active-inactive-img-bg-color: rgba(255, 94, 94, 0.2);
    --status-active-bg-color: rgba(218, 245, 230, 1);
    --status-active-text-color: rgba(58, 201, 119, 1);
    --status-inactive-bg-color: rgba(255, 222, 222, 1);
    --status-inactive-text-color: rgba(255, 94, 94, 1);
    --status-pending-text-color: rgba(240, 207, 43, 1);
    --status-pending-bg-color: rgba(240, 207, 43, 0.2);
    --status-inprogress-bg-color: rgba(255, 94, 0, 0.2);
    --status-inprogress-text-color: rgba(255, 94, 0, 1);
    --status-reopen-bg-color: rgba(21, 104, 225, 0.2);
    --status-reopen-text-color: rgba(21, 104, 225, 1);
    --status-inprogress-bg-color:  rgba(255, 94, 0, 0.2);
    --status-inprogress-text-color: rgba(255, 94, 0, 1);
    --next-btn-bg-color: rgba(44, 44, 44, 1);
    --list-group-text1-color: rgba(136, 136, 136, 1);
    --list-group-text2-color: rgba(44, 44, 44, 1);
    // --next-btn-bg-color: rgba(6, 201, 244, 0.2);
    --next-btn-text-color: rgba(255, 255, 255, 1);
    --buttons-bg-color: rgba(6, 201, 244, 1);
    --cancel-btn-bg-color: rgba(255, 222, 222, 1);
    --cancel-btn-text-color: rgba(255, 94, 94, 1);
    --previous-btn-bg-color: rgba(217, 217, 217, 1);
    --previous-btn-text-color: rgba(136, 136, 136, 1);
    --header-text-color: #495057;
    --menu-text-color: #6d7080;
    --primary-text-color: rgba(0, 0, 0, 1);
    // --secondary-text-color: #878a99;
    --secondary-text-color: rgba(110, 110, 110, 1);
    --tik-mark-color: rgba(90, 201, 119, 1);
    --view-page-text-color: rgba(69, 69, 69, 1);
    --filter-section-bg-color: #FFFFFF;
    --filter-table-section-border-color: #E5E5E5;
    --assignTo-bg-color: rgba(52, 58, 64, 0.2);
    --settings-img-bg-color: rgba(220, 249, 255, 1);
    --user-profile-text-color: rgba(0, 32, 96, 1);
    --over-all-count-bg-color: rgba(6, 201, 244, 0.2);
    --balance-count-bg-color: rgba(52, 58, 64, 1);
    --scroller-bg-color: rgba(0, 0, 0, 0.2);
    --dashboard-dropdown-btn-bg-color: rgba(242, 242, 242, 1);
    --login-bg-color: rgba(32, 42, 93, 0.8);
    --accordion-body-color: rgba(52, 58, 64, 0.02);

    // --border-color: #ddd;
    --border-color: rgba(204, 204, 204, 1);
    --filter-items-border-color: rgba(229, 229, 229, 1);
    --modulename-border-color: rgba(231, 231, 231, 1);
    --table-header-color: rgba(245, 245, 245, 1);
    --modal-label-color: #898989;
    --table-data-bg-color:rgba(52, 58, 64, 0.05);

    --lm-box-shadow: 0 1px 6px rgba(0, 0, 0, 0.2);
    --lm-box-shadow-focus: 0 1px 6px rgba(13, 110, 253, 0.36);
    --leave-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25) inset;
    --tabs-box-shadow: 0px -1px 4px 0px rgba(0, 0, 0, 0.2) inset;
    --3d-shadow-effect: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
    --3d-shadow-effect-focus: rgba(94, 129, 176, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(30, 128, 226, 0.35) 0px -2px 6px 0px inset;
    --active-menu-bg-color: linear-gradient(90deg, #E5E5E5 45.43%, #F5F5F5 100%);

}

.status-pending {
    --status-btn-bg-color: var(--status-pending-bg-color);
    --status-btn-text-color: var(--status-pending-text-color);
}

.status-approved {
    --status-btn-bg-color: var(--status-active-bg-color);
    --status-btn-text-color: var(--status-active-text-color);
}

.status-rejected {
    --status-btn-bg-color: var(--status-inactive-bg-color);
    --status-btn-text-color: var(--status-inactive-text-color);
}
.status-reopen {
    --status-btn-bg-color: var(--status-reopen-bg-color);
    --status-btn-text-color: var(--status-reopen-text-color);
}
.status-inprogress {
    --status-btn-bg-color: var(--status-inprogress-bg-color);
    --status-btn-text-color: var(--status-inprogress-text-color);
}
.status-cancelled {
    --status-btn-bg-color: var(--active-inactive-img-bg-color);
    --status-btn-text-color: var(--cancel-btn-text-color);
}

