@import './root';
@import './mixins';

.leaves-cards-container {
    display: flex;
    flex-wrap: wrap;
    padding: 1rem 1.5rem 0rem 0rem;
    margin: 0 !important;
    background-color: var(--sidenavbar-color);
    gap: 1%;

    .leave-cards {
        @include displayFlexCenter;
        height: 125px;
        border: 1px solid var(--header-border-color);
        border-radius: 3%;
        background-color: var(--user-profile-bg-color);
        flex: 1 1 17.5%;
        cursor: pointer;

        &.active-card {
            // background-color: var(--filter-items-border-color);
            box-shadow: var(--leave-box-shadow);
        }
    }

    .leave-details-card {
        flex: 1 1 12.5%;
    }

    .leave-cards-counts {
        @include displayColumnCenter;

        b {
            @include displayFlexCenter;
            height: 40px;
            width: 40px;
            border-radius: 50%;
            margin-top: 1.2rem;
            background-color: var(--sidenavbar-color);
            color: var(--sidenavbar-text-color);
        }

        p {
            margin-top: 1rem;
        }

        .overAll-count {
            background-color: var(--over-all-count-bg-color) !important;
            color: var(--buttons-bg-color) !important;
        }

        .leave-overall-p-div {
            color: var(--buttons-bg-color) !important;
            font-weight: 600 !important;
        }

        .pending-leave-count {
            background-color: var(--status-pending-bg-color) !important;
            color: var(--status-pending-text-color) !important;
        }

        .leave-pending-p-div {
            color: var(--status-pending-text-color) !important;
            font-weight: 600 !important;
        }

        .approve-leave-count {
            background-color: var(--total-customer-img-bg-color) !important;
            color: var(--status-active-text-color) !important;
        }

        .leave-approved-p-div {
            color: var(--status-active-text-color) !important;
            font-weight: 600 !important;
        }

        .rejected-leave-count {
            background-color: var(--active-inactive-img-bg-color) !important;
            color: var(--status-inactive-text-color) !important;
        }

        .leave-rejected-p-div {
            color: var(--status-inactive-text-color) !important;
            font-weight: 600 !important;
        }

        .leave-balance {
            background-color: var(--balance-count-bg-color) !important;
        }

        .leave-balance-p-div {
            color: var(--balance-count-bg-color) !important;
            font-weight: 600 !important;
        }
    }
}

.leave-details-table .MuiTableContainer-root {
    min-height: 35rem !important;
    max-height: 35rem !important;
}

.employee-profile-col {
    padding: 1rem 2rem 0rem 0rem !important;

    .employee-profile-container {
        @include displayColumnCenter;
        border: 1px solid var(--filter-items-border-color);
        border-radius: 1%;
        background-color: var(--user-profile-bg-color);
        padding: 1.3rem;

        .employee-profile-img {
            height: 120px;
            width: 120px;
        }

        .employee-details {
            @include displayColumnCenter;
            text-align: center;
            padding: 1rem;

            p {
                color: var(--previous-btn-text-color);
                margin: 0;
            }

            span {
                color: var(--next-btn-bg-color);
                font-weight: 600;
                font-size: 0.85rem;
            }
        }
    }
}


.add-single-bulk {
    .status-btn {
        background-color: var(--status-btn-bg-color) !important;
        color: var(--status-btn-text-color) !important;
        font-weight: 500;
        padding: 0;
        padding-left: 8px;
        border: none;
        font-size: 0.8rem;
        border-radius: 2px 0px 0px 2px;

        &:hover {
            background-color: var(--status-btn-bg-color) !important;
            color: var(--status-btn-text-color) !important;
        }

        &:focus-visible {
            background-color: var(--status-btn-bg-color) !important;
            color: var(--status-btn-text-color) !important;
            box-shadow: none;
            outline: none;
        }

        &:active {
            background-color: var(--status-btn-bg-color) !important;
            color: var(--status-btn-text-color) !important;
        }

        img {
            // height: 24px;
            width: 24px;
            height: 30px;
            padding: 8px;
            background: var(--filter-items-border-color);
            border-radius: 0px 2px 2px 0px;
        }

    }

    .status-btn-approved {
        padding: 8px 16px;
        /* Increased padding for Approved state */
    }

    .status-btn-rejected {
        padding: 8px 20px;
        /* Increased padding for Rejected state */
    }

    .status-btn-reopen,
    .status-btn-cancel,
    .status-btn-resolved,
    .status-btn-pending,
    .status-btn-inprogress {
        display: flex;
        // justify-content: space-between;
        justify-content: center;
        align-items: center;
        height: 35px;
        width: 106px !important;
        border-radius: 2px 2px 2px 2px;
    }
}


.status-btn.dropdown-toggle::after {
    display: none;
}

// HolidaySchedules
.calendar-grid {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    margin: 0;
    padding-top: 5px;
}

.calendar-date {
    width: 100%;
    height: 50px;
    @include displayFlexCenter;
    font-size: 14px;
}

.calendar-day-name {
    background-color: rgba(214, 216, 217, 1);
    color: var(--list-group-text2-color);
    font-weight: 400;
}

.empty {
    background-color: transparent;
    border: none;
}

.holiday {
    background-color: rgba(247, 119, 119, 0.2);
    border-radius: 5px;
    position: relative;
}

.holiday::after {
    content: '';
    display: block;
    width: 4px;
    height: 4px;
    background-color: var(--cancel-btn-text-color);
    border-radius: 50%;
    position: absolute;
    bottom: 5px;
}


.holiday-schedule-card {
    background-color: var(--user-profile-bg-color);
    border: 1px solid var(--filter-items-border-color);
    padding: 10px;
    height: 720px !important;

    .holiday-schedule-card-body {
        border-radius: 1%;

        .holiday-schedule-chevron-month-div {
            @include displayFlexBetween;
            padding-top: 1rem;

            .holiday-schedule-chevron-img {
                @include displayFlexCenter;
                height: 40px;
                width: 40px;
                background-color: rgba(233, 233, 233, 1);
                border-radius: 5px;

                &.active {
                    background-color: black !important;
                }
            }
        }

        .holiday-list {
            max-height: 226px;
            overflow: auto;

            &::-webkit-scrollbar {
                width: 3px;
            }

            &::-webkit-scrollbar-track {
                background: var(--filter-section-bg-color);
            }

            &::-webkit-scrollbar-thumb {
                background: var(--scroller-bg-color);
                border-radius: 10px;

                &::-webkit-scrollbar-thumb:hover {
                    background: var(--scroller-bg-color);
                }
            }
        }
    }
}

.leaves-contact-number {
    color: var(--buttons-bg-color) !important;
    font-weight: 500 !important;
}

// HolidaySchedules