@import '../root';

// .lm-sidebar {
//     width: 18vw;

//     .pro-side-bar {
//         background-color: var(--primary-bg-color);
//         ;
//         // box-shadow: $rightBoxShadow;
//         height: 100vh;
//         position: fixed;
//         top: 0;
//         left: 0;
//         z-index: 9;

//         &.expanded-bar {
//             width: 15vw;
//             transition: all 0.35s ease;
//             transform: translateX(0);
//         }

//         &.collapsed-bar {
//             width: 5vw;
//             transition: all 0.35s ease;
//             transform: translateX(0);
//         }

//         .content-of-expended-bar {
//             .app-logo {
//                 padding: 0.5rem;
//                 height: 3.5rem;
//                 background-color: var(--primary-bg-color);
//                 position: sticky;
//                 top: 0;
//                 left: 0;

//                 .logo-part {
//                     color: var(--primary-bg-color);
//                     ;
//                     display: flex;
//                     align-items: center;
//                     justify-content: center;
//                     height: 3rem;
//                 }
//             }

//             .sidebar-content {
//                 max-height: 81vh;
//                 overflow-x: hidden;

//                 .sidebar-item {
//                     padding: 0.8rem 0.85rem;
//                     box-shadow: -5px 0px 1px 0px lightgray;

//                     &.active {
//                         // background-image: $sidebarActive;
//                     }

//                     .sidebar-menu-item {
//                         color: var(--primary-bg-color);

//                         .icon-n-text-part {
//                             // @include displayAlignJustify(flex, center, start);

//                             .material-symbols-outlined {
//                                 &.link-icon {
//                                     font-size: 1.3rem !important;
//                                     padding-right: 0.3rem;
//                                 }
//                             }

//                             .link-text {
//                                 color: var(--menu-text-color);
//                                 ;
//                                 font-size: 0.95rem;
//                             }
//                         }
//                     }
//                 }
//             }

//             .downloadApp-part {
//                 position: fixed;
//                 bottom: 0;
//                 left: 0;
//                 width: 100%;
//                 border-top: 0.06rem solid lightgrey;

//                 .download-app {
//                     display: flex;
//                     align-items: center;
//                     justify-content: center;
//                     height: 8.6vh;
//                     padding: 0.5rem;
//                 }
//             }
//         }

//         .content-of-collapsed-bar {
//             .app-logo {
//                 padding: 0.5rem;
//                 height: 3.5rem;
//                 background-color: var(--primary-bg-color);
//                 ;
//                 top: 0;
//                 left: 0;

//                 .logo-part {
//                     color: var(--primary-bg-color);
//                     ;
//                     display: flex;
//                     align-items: center;
//                     justify-content: center;
//                     position: sticky;
//                     height: 3rem;
//                 }
//             }

//             .sidebar-content {
//                 max-height: 81vh;
//                 width: 100%;
//                 overflow-x: hidden;

//                 .sidebar-item {
//                     padding: 0.6rem 0.85rem;
//                     font-size: 1.5rem;
//                     // @include displayAlignJustify(flex, center, center);

//                     &.active {
//                         // background-image: $sidebarActive;
//                     }

//                     .sidebar-icon {
//                         // @include displayAlignJustify(flex, center, center);

//                         .link-icon {
//                             // color: $primaryTextColor;
//                         }
//                     }
//                 }
//             }

//             .downloadApp-part {
//                 position: fixed;
//                 bottom: 0;
//                 left: 0;
//                 width: 100%;
//                 border-top: 0.06rem solid lightgrey;

//                 .download-app {
//                     display: flex;
//                     align-items: center;
//                     justify-content: center;
//                     height: 8.6vh;
//                     padding: 0.5rem;
//                 }
//             }
//         }
//     }

// }

.lm-sidevar {
    .container {
        position: fixed;
        height: 100vh;
        transition: all 0.5s ease;
        background-color: var(--sidenavbar-color);
        z-index: 9;
        padding: 0;

        .active {
            border-right: 4px solid var(--white);

            img {
                filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg) brightness(103%) contrast(103%);
            }
        }

        .sidebar-chevron-right {
            transform: rotate(-90deg);
            transition: transform 0.3s ease;
            margin-left: 0.9rem;
        }

        .sidebar-chevron-down {
            transform: rotate(0deg);
            transition: transform 0.3s ease;
            margin-left: 0.9rem;
        }


    }

    .button {
        background-color: var(--black);
        border: none;
        width: 2.5rem;
        height: 2.5rem;
        // border-radius: 50%;
        // margin: 0.5rem 0 0 0.5rem;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        background-color: var(--buttons-bg-color);
        right: -2.5rem;
        padding: 1rem;

        img {
            width: 3rem;
        }

        &::before,
        &::after {
            content: "";
            background-color: var(--white);
            height: 2px;
            width: 1rem;
            position: absolute;
            transition: all 0.3s ease;
        }

        &::before {
            top: 1rem;
            transform: rotate(0);
        }

        &::after {
            top: 1.5rem;
            transform: rotate(0);
        }

        &.clicked::before {
            top: 1.5rem;
            transform: rotate(135deg);
        }

        &.clicked::after {
            top: 1.2rem;
            transform: rotate(-135deg);
        }
    }

    .sidebar-container {
        background-color: var(--black);
        // width: 3.5rem;
        height: 80vh;
        margin-top: 1rem;
        border-radius: 0 30px 30px 0;
        // padding: 1rem 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        position: relative;

        .company-small-logo {
            height: 60%;
            padding-left: 1.5rem;
            display: block;
        }

        .company-logo {
            padding-left: 1.5rem;
            display: none;
        }
    }

    .container.clicked .company-logo {
        display: block;
        /* Hide large logo when sidebar is clicked */
    }

    .container.clicked .company-small-logo {
        display: none;
        /* Still show small logo when sidebar is clicked */
    }

    .logo {
        width: 2rem;
        color: var(--white);

        svg {
            width: 100%;
            height: auto;
        }
    }

    .slick-bar {
        color: var(--white);
        list-style: none;
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: var(--black);
        padding: 2rem 0;
        position: absolute;
        // top: 6rem;
        left: 0;
        width: 3.5rem;
        transition: all 0.5s ease;
        border-radius: 0 30px 30px 0;

        &.clicked {
            width: 14rem;
        }
    }

    .item {
        text-decoration: none;
        color: var(--white);
        width: 100%;
        padding: 0.8rem 0;
        cursor: pointer;
        display: flex;
        padding-left: 1.5rem;

        &:hover {
            border-right: 4px solid var(--white);

            svg {
                filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg) brightness(103%) contrast(103%);
            }
        }

        svg {
            width: 1.2rem;
            height: auto;
            filter: invert(92%) sepia(4%) saturate(1033%) hue-rotate(169deg) brightness(78%) contrast(85%);
        }

        &.clicked {
            background-color: var(--active-menu-bg-color);

            span {
                color: rgba(6, 201, 244, 1);
            }
        }

    }

    .sub-menu {
        .sub-item {
            &.clicked {
                background-color: var(--active-menu-bg-color);

                span {
                    color: rgba(6, 201, 244, 1);
                }
            }
        }
    }

    .text {
        width: 0;
        overflow: hidden;
        margin-left: 0;
        transition: all 0.3s ease;
        color: var(--sidenavbar-text-color);
        font-size: 0rem;
        text-wrap: nowrap;

        &.clicked {
            width: 100%;
            margin-left: 0.9rem;
            font-size: 0.8rem;
        }
    }

    .profile {
        width: 3rem;
        height: 3rem;
        padding: 0.5rem 1rem;
        border-radius: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 0;
        background-color: var(--black);
        color: var(--white);
        transition: all 0.3s ease;

        &.clicked {
            width: 14rem;
            margin-left: 9rem;
        }

        img {
            width: 2.5rem;
            height: 2.5rem;
            border-radius: 50%;
            cursor: pointer;

            &:hover {
                border: 2px solid var(--grey);
                padding: 2px;
            }
        }
    }

    .details {
        display: none;
        justify-content: space-between;
        align-items: center;

        &.clicked {
            display: flex;
        }
    }

    .name {
        padding: 0 1.5rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        h4 {
            display: inline-block;
        }

        a {
            font-size: 0.8rem;
            text-decoration: none;
            color: var(--grey);

            &:hover {
                text-decoration: underline;
            }
        }
    }

    .logout {
        border: none;
        width: 2rem;
        height: 2rem;
        background-color: transparent;

        svg {
            width: 100%;
            height: auto;
            filter: invert(15%) sepia(70%) saturate(6573%) hue-rotate(2deg) brightness(100%) contrast(126%);
            transition: all 0.3s ease;

            &:hover {
                border: none;
                padding: 0;
                opacity: 0.5;
            }
        }
    }
}

.slick-bar .item.clicked {
    background: var(--active-menu-bg-color);

    img {
        filter: invert(65%) sepia(65%) saturate(2377%) hue-rotate(145deg) brightness(99%) contrast(95%);
    }

    span {
        color: var(--buttons-bg-color);
        font-weight: 600;
    }
}

.sub-menu {
    cursor: pointer;
}

.sub-menu .sub-item.clicked {
    background: var(--active-menu-bg-color);

    img {
        filter: invert(65%) sepia(65%) saturate(2377%) hue-rotate(145deg) brightness(99%) contrast(95%);
    }

    span {
        color: var(--buttons-bg-color);
    }
}

@media (min-width:1440px) and (max-width: 1919px) {
    .lm-top-bar {
        margin-left: 3.5vw !important;
        width: calc(100% - 3.5vw) !important;

        &.clicked {
            margin-left: 15.5vw !important;
            width: calc(100% - 15.5vw) !important;
        }
    }


    .lm-top-bar-download {
        margin-left: 15.5vw !important;
        width: calc(100% - 15.5vw) !important;
        top: 55px !important;
    }

    .lm-sidevar {
        .container {
            width: 3.5vw !important;
        }

        .button {
            width: 3.1rem !important;
            height: 3.1rem !important;
            right: -3.15rem !important;
        }
    }

    .lm-sidevar {
        .container {
            &.clicked {
                width: 15.5vw !important;
            }
        }
    }

    .lm-main-body {
        margin-left: 3.5vw !important;
        width: calc(100% - 3.5vw) !important;

        &.clicked {
            margin-left: 15.5vw !important;
            width: calc(100% - 15.5vw) !important;
        }
    }

    .slick-bar {
        .text {
            font-size: 0; // default padding
        }

        &.clicked {
            .text {
                font-size: 0.8rem; // padding when .slick-bar has the 'clicked' class
            }
        }
    }
}

@media (min-width: 1920px) and (max-width: 2560px) {
    .lm-sidevar {
        .container {
            &.clicked {
                width: 12.5vw !important;
            }
        }
    }

    .lm-sidevar {
        .container {
            width: 3.5vw !important;
        }

        .button {
            width: 3.1rem !important;
            height: 3.1rem !important;
            right: -3.15rem !important;
        }
    }

    .lm-main-body {
        margin-left: 3.5vw !important;
        width: calc(100% - 3.5vw) !important;

        &.clicked {
            margin-left: 12.5vw !important;
            width: calc(100% - 12.5vw) !important;
        }
    }

    .lm-top-bar {
        margin-left: 3.5vw !important;
        width: calc(100% - 3.5vw) !important;

        &.clicked {
            margin-left: 12.5vw !important;
            width: calc(100% - 12.5vw) !important;
        }
    }
}