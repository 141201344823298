@import './root';

@mixin displayFlexCenter {
    display: flex;
    align-items: center;
    justify-content: center;
}

@mixin displayFlex2Start {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

@mixin displayFlex2End {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
}

@mixin displayFlexBetween {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

@mixin displayFlexCenterStart {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

@mixin displayFlexCenterEnd {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

@mixin displayColumnStartCenter {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
}

@mixin displayColumnCenter {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

@mixin customScrollbar {
    ::-webkit-scrollbar {
        width: 4px;
    }

    ::-webkit-scrollbar-track {
        background-color: var(--table-header-color);
    }

    ::-webkit-scrollbar-thumb {
        background-color: var(--scroller-bg-color);
        border-radius: 4px;
    }
}