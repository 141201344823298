.inventory-container {
    display: flex;
    // justify-content: center;
    padding: 10px;
}

.dropdown-filters {
    display: flex;
    // flex-wrap: wrap;
    align-items: flex-start;
    // background: #f8f9fa;
    padding: 10px 0px;
    // border-radius: 5px;
    // box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
    text-wrap: nowrap;
    // overflow: auto;
    gap: 1rem;
}

.filter-section {
    position: relative;
    display: flex;
    // flex-direction: column;
    align-items: center;
}

.filter-item {
    padding: 8px 12px;
    cursor: pointer;
    border-radius: 3px;
    text-align: center;
    font-size: 0.8rem;
    color: var(--modal-label-color);
    font-weight: 400;
}

.divider {
    width: 2px;
    height: 20px;
    background: gray;
    margin: 5px 10px;
}

.filter-dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background: white;
    border: 1px solid #ddd;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
    padding: 5px;
    max-height: 335px;
    height: 335px;
    overflow-y: auto;
    z-index: 1000;
}

.filter-option {
    padding: 5px;
    cursor: pointer;
    border-bottom: 1px solid #ddd;
    text-align: center;
    font-size: 0.8rem;
    color: var(--modal-label-color);
    font-weight: 400;
}

.filter-option:hover {
    background: rgba(6, 201, 244, 1);
    color: white;
}

.filter-item-container {
    position: relative;
}

.power-section-second-div {
    display: flex;
    align-items: center;
    justify-content: center;

    .recomended-fit {
        position: absolute;
        top: 400px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .ideal-temp {
        position: absolute;
        top: 470px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    .power-section-select-div {
        position: absolute;
        top: 520px;
        width: 100%;
        padding: 10px;
    }
}

.options-disabled {
    pointer-events: none;
    opacity: 0.5;
    color: #FFFFFF;
    background-color: gray !important;
}

.highlight {
    background-color: rgba(6, 201, 244, 0.5);
    color: #FFFFFF;
}

.filter-option.selected {
    background-color: rgba(6, 201, 244, 0.5);
    color: white;
    font-weight: bold;
    border-radius: 4px;
}

.motor-le-ps {
    position: absolute;
    top: 410px;
}