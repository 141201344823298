@import '../root';
@import '../mixins';

.signin-main-container {
    // padding: 3.0rem;
    height: 100%;
    width: 100%;

    .lm-signin-login-row {
        height: 100%;
        margin: 0;

        .lm-signin-image-col {
            // height: 100%;
            // @include displayColumnStartCenter;
            align-items: center;
            // padding: 1rem 4rem;
            padding: 0;
            // background-image: url('../../images/Login/loginRectangleImg.svg');
            background-size: cover;
            background-position: center;
            opacity: 1;
            position: relative;
            background: rgba(32, 42, 93, 1);

            .lm-logo-img-div {
                // height: 9%;
                display: flex;
                justify-content: center;
                align-items: center;
                // padding: 0.5rem 0.2rem;
                margin-bottom: 1.2rem;
                border-radius: 6px;
                // box-shadow: var(--3d-shadow-effect);

                // .lm-logo-img {
                //     height: 100%;
                //     object-fit: cover;
                //     width: auto;
                // }
            }

            .lm-bg-img-div {
                height: 100vh;

                .lm-bg-img {
                    height: 100%;
                    object-fit: cover;
                    width: 100%;
                    opacity: 0.2;
                    // padding: 40px;
                }
            }

            .carousel-img {
                width: 100%;
                height: 100vh;
                object-fit: cover;
                opacity: 0.7;
            }

            .carousel-content {
                position: absolute;
                top: 45%;
                /* Adjust this value to move the content up or down */
                left: 50%;
                transform: translate(-50%, -50%);
                text-align: center;

                .carousel-logo {
                    width: 90%;
                }

                .carousel-text {
                    font-size: 0.875rem !important;
                    // margin-top: -200px; 
                    color: var(--next-btn-text-color);
                    font-weight: 600;
                    position: absolute;
                    top: -190px;
                }
            }
        }

        .lm-signin-form-col {
            @include displayColumnCenter;
            // padding: 4rem 10rem;
            background-color: #f5f5f5;
            position: relative;

            .company-small-logo {
                position: absolute;
                top: 2.5rem;
                left: 2.5rem;
            }

            .lm-wlcm-txt {
                font-size: 1.4rem;
                color: var(--primary-color);
                font-weight: 600;
                margin: 0;
                line-height: 37.5px;
            }

            p {
                font-weight: 400;
                color: var(--secondary-text-color);
                margin: 0;
                line-height: 21px;
            }

            span {
                font-weight: 400;
                color: var(--secondary-text-color);
                margin: 0;
                font-size: 0.7rem !important;
            }

            .lm-login-form {
                padding: 0.4rem 12rem;
                text-align: center;
                width: 100%;

                .input-box {
                    .input-box2 {
                        padding-top: 23px;
                    }

                    label {
                        @include displayFlex2Start;
                        color: var(--secondary-text-color);
                        font-size: 0.8rem;
                        line-height: 19.5px;
                    }

                    input {
                        padding: 0.67rem 0.75rem;
                        border-radius: 0.475rem;
                    }

                    .pass-hide-show {
                        img {
                            position: absolute;
                            top: 58px;
                            right: 16px
                        }
                    }
                }

                .forgot-pass-n-remind-me {
                    @include displayFlexBetween;
                    color: var(--secondary-text-color);
                    padding-top: 3%
                }

                .lm-login-form-btn {
                    @include displayFlexBetween;
                    padding-top: 3rem;

                    .lm-pswd-reset {
                        color: var(--secondary-text-color)
                    }

                    .btn {
                        border: none;
                        // box-shadow: var(--3d-shadow-effect);
                        width: 100%;
                        // background-color: var(--buttons-bg-color);
                        // color: var(--filter-section-bg-color);
                        height: 45px;
                        background-color: var(--over-all-count-bg-color) !important;
                        color: var(--buttons-bg-color) !important;
                        font-weight: 600;
                        border: 1.5px solid var(--buttons-bg-color);

                        &:hover {
                            background-color: var(--buttons-bg-color) !important;
                            color: var(--filter-section-bg-color) !important;
                        }

                        &:focus-visible,
                        &:focus {
                            background-color: var(--buttons-bg-color) !important;
                            color: var(--filter-section-bg-color) !important;
                            box-shadow: none;
                            outline: none;
                        }

                        &:active {
                            background-color: var(--buttons-bg-color) !important;
                            color: var(--filter-section-bg-color) !important;
                        }
                    }
                }

                .forgot-pass-n-remind-me {
                    .login-remember-me {
                        font-size: 0.8rem;
                    }

                    .forget-pasword-div a {
                        text-decoration: none;
                        color: var(--primary-color);
                        font-size: 0.9rem;
                    }
                }

                .divider-container {
                    @include displayFlexCenter;
                    padding: 18px;

                    .line {
                        flex: 1;
                        height: 1px;
                        background-color: var(--secondary-text-color);
                        margin: 0 10px;
                        border: 1px solid var(--border-color);
                    }

                    .text {
                        white-space: nowrap;
                        margin: auto;
                    }
                }

                .lm-devider {
                    border-bottom: 1px solid var(--border-color);
                    line-height: 0.3;
                    text-align: center;

                    span {
                        padding: 0.2rem 1rem;
                        background-color: var(--primary-bg-color);
                    }
                }

                .lm-login-btns-section {
                    @include displayFlexBetween;
                    padding: 0.7rem;
                    justify-content: center;

                    .btn-div {
                        padding: 0 .2rem;
                        // width: 100%;
                        position: relative;

                        .btn {
                            width: 100%;
                            background-color: var(--primary-bg-color);
                            border: none;
                            box-shadow: var(--3d-shadow-effect);

                            img {
                                height: 30px;
                            }
                        }

                        .sr-only {
                            position: absolute;
                            width: 1px;
                            height: 1px;
                            padding: 0;
                            margin: -1px;
                            overflow: hidden;
                            clip: rect(0, 0, 0, 0);
                            border: 0;
                        }

                        &:hover .login-tooltip {
                            visibility: visible; // Make tooltip visible on hover
                            opacity: 1; // Make tooltip fully opaque
                            top: -30px; // Adjust this value to position the tooltip correctly
                            left: 50%;
                            transform: translateX(-50%);
                        }

                        .login-cmp-icons {
                            &:hover {
                                box-shadow: var(--lm-box-shadow);
                            }
                        }
                    }

                    .login-tooltip {
                        position: absolute;
                        top: -40px; // Ensure it is placed above the button
                        left: 50%;
                        transform: translateX(-50%);
                        background-color: var(--next-btn-text-color); // Background color for visibility
                        color: var(--next-btn-bg-color); // Text color
                        padding: 5px;
                        border-radius: 3px;
                        font-size: 0.8rem;
                        white-space: nowrap; // Prevent text wrapping
                        opacity: 0;
                        visibility: hidden; // Hidden by default
                        transition: opacity 0.3s ease, top 0.3s ease; // Smooth transitions
                    }
                }

                .otp-input-box {
                    display: flex;
                    gap: 25px;
                    padding: 2rem 0rem;
                    justify-content: center;

                    input {
                        height: 46px;
                        width: 50px;
                        text-align: center;
                    }
                }

                .forget-password-verify-btn {
                    padding-top: 0rem;
                }

                .resend-otp-div {
                    padding-top: 2rem;

                    span {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-size: 0.8rem !important;

                        p {
                            color: var(--buttons-bg-color) !important;
                            font-size: 0.8rem !important;
                        }
                    }
                }

                .back-to-signin-div {
                    p {
                        font-size: 0.8rem !important;

                        a {
                            color: var(--buttons-bg-color) !important;
                            text-decoration: none !important;
                            font-size: 0.8rem !important;
                        }
                    }
                }
            }

            .lm-signup-link {
                color: var(--primary-color);
                font-weight: 500;
            }

            .not-registered {
                color: var(--secondary-text-color);
            }

            .security-img {
                @include displayColumnCenter;
                width: 100%;

                img {
                    width: 100px;
                    height: 125px;
                    padding: 1rem !important;
                }
            }

            .forget-password-company-logo {
                img {
                    position: absolute;
                    top: 15%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    width: 300px;
                    height: 38px;
                }
            }

            .verification-btn {
                @include displayColumnCenter;
                padding: 0rem !important;

                img {
                    padding: 1rem;
                }
            }

            .company-verification-content {
                span {
                    color: var(--primary-text-color);
                    font-size: 1rem !important;
                    @include displayColumnCenter;
                    padding: 0.5rem 0rem 0.5rem 0rem;
                }
            }
        }

        .verification-img-btn-col {
            padding: 0 !important;
        }

        .forgot-password-form-col {
            padding: 0 !important;

            .forgot-password-reset-text {
                font-size: 0.8rem !important;
            }
        }

        .forget-password-second-col {
            height: 100%;
            position: relative;

            .lm-bg-img-div {
                height: 100%;

                img {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
            }
        }
    }
}


// @media (max-width: 768px)
@media (min-width: 768px) and (max-width: 1023px) {
    .signin-main-container {
        .lm-signin-login-row {
            .lm-signin-form-col {
                @include displayColumnCenter;
                padding: 4rem 0rem;
                background-color: #f5f5f5;
            }
        }
    }

    .signin-main-container {
        .lm-signin-login-row {
            .forget-password-second-col {
                .lm-bg-img-div img {
                    width: 80%;
                }
            }

            .lm-signin-form-col {
                padding: 4rem 1rem !important;

                .lm-wlcm-txt {
                    font-size: 0.8rem;
                }

                span {
                    font-size: 0.65rem !important;
                }


                .lm-login-form {
                    padding: 1rem 0rem;
                    text-align: center;
                    width: 100%;

                    .forgot-pass-n-remind-me {
                        flex-direction: column;
                        align-items: flex-start;

                        .signIn-footer {
                            line-height: 35px;
                        }
                    }

                    .otp-input-box {

                        input {
                            height: 35px;
                            width: 35px;
                        }
                    }

                    .forget-password-verify-btn {
                        padding-top: 1rem;
                    }
                }

            }
        }
    }
}

@media (min-width: 1024px) and (max-width: 1440px) {
    .signin-main-container {
        .lm-signin-login-row {
            .lm-signin-form-col {
                @include displayColumnCenter;
                // padding: 4rem 4rem;
                background-color: #f5f5f5;
                padding-top: 3rem;

                .company-small-logo {
                    top: 1rem;
                    left: 1rem;
                }

                .lm-login-form {
                    padding: 0.4rem 6rem;
                    text-align: center;
                    width: 100%;

                    .input-box {
                        input {
                            padding: 0.375rem 0.75rem !important;
                            border-radius: 0.475rem !important;
                        }

                        .pass-hide-show {
                            img {
                                top: 53px;
                            }
                        }
                    }

                    .lm-login-form-btn {
                        .btn {
                            height: 35px;
                        }
                    }

                    .otp-input-box {

                        input {
                            height: 35px;
                            width: 35px;
                        }
                    }
                }

                // .security-img img {
                //     width: 80px !important;
                //     height: 100px !important;
                // }

                .lm-wlcm-txt {
                    font-size: 1rem !important;
                }
            }

            .forget-password-company-logo {
                img {
                    top: 10% !important;
                }
            }
        }
    }
}